import gql from "graphql-tag";
import { Pass } from "./fragments";

export const getAllPasses = gql`
query passes( $input: PassesQueryInput ) {
  passes (input: $input ) {
    data {
      ${Pass}
    }
    paging {
      total
      limit
      offset
      pages
      page
    }
  }
}
`;

export const getOnePass = gql`
query pass($id: ID!) {
  pass (id: $id ) {
    data {
      ${Pass}
    }
  }
}
`;
