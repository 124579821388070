/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",    
    "aws_cognito_region": "eu-central",
    "aws_user_pools_id": "eu-central-1_oleBaEWRJ",
    "aws_user_pools_web_client_id": "6ffq10j57gh06aolj430uhelkg",
    "oauth": {},    
    "aws_user_files_s3_bucket": "wondersouq-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;

