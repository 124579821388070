import { useApolloClient } from "react-apollo";
import { getOnePass } from "../graphql/queries";
import { mediaRecordsQuery } from "../graphql/media-gallery/queries";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const useFetchData = () => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return (passId, ref, passesTypeImage) => {
    return apolloClient
      .query({
        query: getOnePass,
        variables: {
          id: passId,
        },
        fetchPolicy: "network-only",
      })
      .then(res => {
        let uploadedMediaIds;

        if (passesTypeImage === "badgeId") {
          uploadedMediaIds = res.data.pass.data.badgeId;
        } else {
          uploadedMediaIds = res.data.pass.data.thumbnailId;
        }

        if (ref.current) {
          ref.current.id = uploadedMediaIds;
        }
        return apolloClient.query({
          query: mediaRecordsQuery,
          variables: {
            shopId: "b9XYSK5LcqtNf2Ltw",
            mediaRecordIds: uploadedMediaIds,
          },
          fetchPolicy: "network-only",
        });
      })
      .then(res => {
        return res.data.mediaRecords.nodes[0];
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      });
  };
};
