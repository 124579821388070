import gql from "graphql-tag";

const Pass = `
  id
  name
  duration
  cost
  isActive
  thumbnailId
  thumbnailUrl
  badgeId
  badgeUrl
  createdAt
  updatedAt
`;

export const restrictSpace = gql`
  mutation restrictSpaceAccess($input: RestrictSpaceAccessInput!) {
    restrictSpaceAccess(input: $input) {
      data {
        id
        name
        isRestricted
        restrictedToIds
        restrictedTo{
            ${Pass}
        }
        }
    }
  }
`;

export const removeSpaceRestrictions = gql`
  mutation removeSpaceAccessRestrictions($input: RemoveSpaceAccessRestrictionsInput!) {
    removeSpaceAccessRestrictions(input: $input) {
      data {
        id
        name
        isRestricted
        restrictedToIds
        restrictedTo{
            ${Pass}
        }
      }
    }
  }
`;

export const updateSpace = gql`
  mutation updateSpace($input: UpdateSpaceInput!) {
    updateSpace(input: $input) {
      data {
        id
        name
        isRestricted
        restrictedToIds
        restrictedTo{
            ${Pass}
        }
      }
    }
  }
`;
