import * as Yup from "yup";

const numberValidation = Yup.number()
  .transform((value, original) => {
    return original === "" ? undefined : value;
  })
  .required("errors.field_required")
  .test(
    'max-10-digits',
    'errors.max_10_digits',
    (value) => !value || value.toString().length <= 10
  );

export const PassesSchema = Yup.object().shape({
  name: Yup.string().required("errors.field_required"),
  duration: numberValidation,
  cost: numberValidation,
});
