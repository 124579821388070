import React, { useState } from "react";
import FormHeader from "../../../../UI/Form/FormHeader";
import FormText from "../../../../UI/Form/FormText";
import Button from "../../../../UI/Button/Button";
import InputLabel from "../../../../UI/Form/InputLabel";
import { Controller, useFormContext } from "react-hook-form";
import Select from "../../../../UI/Select/Select";
import FormNote from "../../../../UI/Form/FormNote";
import FormInput from "../../../../UI/Form/FormInput";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import FormCard from "../../../../UI/Form/FormCard";
import { constants } from "../../../../constants";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { addMerchantRequestMutation, removeMerchantRequestMutation } from "../../graphql/mutations";
import { pendingMerchantRequestQuery } from "../../graphql/queries";
import { useTranslation } from "react-i18next";

const ShopCategoryForm = ({ shopCategoryIdOld, shopId, shopCatsList, shopCategoryNameOld }) => {
  const [sendingRequest, setSendingRequest] = useState(false);
  const [reason, setReason] = useState("");
  const [categoryChanged, setCategoryChanged] = useState(false);

  const handleChangeCategory = value => {
    setCategoryChanged(value !== shopCategoryIdOld);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    control,
    loading: loadingContext,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [addMerchantRequest] = useMutation(addMerchantRequestMutation, {
    ignoreResults: true,
  });
  const [removeMerchantRequest] = useMutation(removeMerchantRequestMutation, {
    ignoreResults: true,
  });

  const {
    loading: loadingMerchantRequest,
    data: merchantRequestData,
    refetch: refetchPendingData,
  } = useQuery(pendingMerchantRequestQuery, {
    variables: {
      shopId,
      type: constants.MERCHANT_REQUESTS.TYPE.SHOP_CATEGORY,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
    },
    skip: !shopId,
  });

  const sendRequestShopCategory = async () => {
    const newId = getValues("shopCategoryId");

    if (!newId || newId === shopCategoryIdOld) {
      return enqueueSnackbar(t("shops.shop_category_error"), {
        variant: "error",
      });
    }

    setSendingRequest(true);

    await addMerchantRequest({
      variables: {
        input: {
          type: constants.MERCHANT_REQUESTS.TYPE.SHOP_CATEGORY,
          reason,
          changeFrom: shopCategoryIdOld,
          changeTo: newId,
          shopId,
        },
      },
      errorPolicy: "all", // without this, errors wouldn't get populated
    });

    await refetchPendingData();

    setSendingRequest(false);
  };

  const cancelRequestShopCategory = async () => {
    setSendingRequest(true);

    await removeMerchantRequest({
      variables: {
        id: merchantRequestData.pendingMerchantRequest._id,
      },
      errorPolicy: "all", // without this, errors wouldn't get populated
    });

    await refetchPendingData();

    setSendingRequest(false);
  };

  const handleSetReason = e => {
    setReason(e.target.value);
  };

  const loading = loadingContext || sendingRequest || loadingMerchantRequest;

  return (
    <FormCard>
      <FormHeader>{t("shops.shop_category")}</FormHeader>
      {merchantRequestData?.pendingMerchantRequest ? (
        <>
          <FormText>
            {t("shops.change_shop_category_review", {
              fromText: merchantRequestData.pendingMerchantRequest.fromShopCategory[0].name,
              toText: merchantRequestData.pendingMerchantRequest.toShopCategory[0].name,
            })}
          </FormText>
          <Button width="180px" handleClick={cancelRequestShopCategory}>
            {t("shops.cancel_request")}
          </Button>
        </>
      ) : (
        <>
          <InputLabel disabled={loading} error={errors.shopCategoryId}>
            {t("shops.shop_category")}
          </InputLabel>

          <Controller
            name="shopCategoryId"
            control={control}
            render={({ field }) => {
              const isValidValue = shopCatsList?.some(k => k.value === field.value);
              return (
                <Select
                  placeholder={isValidValue ? t("shops.select_shop_category") : shopCategoryNameOld}
                  value={isValidValue ? field.value : ""}
                  onChange={e => {
                    field.onChange(e);
                    handleChangeCategory(e.target.value);
                  }}
                  options={shopCatsList}
                  disabled={loading}
                  error={errors.shopCategoryId}
                />
              );
            }}
          />
          <FormNote>{t("shops.shop_appear_in_streets")}</FormNote>

          <InputLabel error={categoryChanged && !reason} disabled={loading}>
            {t("shops.reason")}
          </InputLabel>
          <FormInput
            placeholder={t("shops.change_shop_category_message")}
            multiline
            rows={4}
            value={reason}
            onChange={handleSetReason}
            disabled={loading}
            error={categoryChanged && !reason}
            margin="0"
          />

          <DialogButtons justifyButtons="flex-end">
            <Button
              disabled={loading || !isValid || (categoryChanged && !reason)}
              width="180px"
              handleClick={sendRequestShopCategory}
            >
              {t("shops.place_request")}
            </Button>
          </DialogButtons>
        </>
      )}
    </FormCard>
  );
};

export default ShopCategoryForm;
