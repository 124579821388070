import moment from "moment/moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Status from "../../../UI/Status/Status";

const useModifiers = () => {
  const { t } = useTranslation();

  const modifyData = useCallback(
    (data, method, options) => {
      return data.map(item => {
        const isExport = method === "export";
        const isActiveType = item.isActive ? "yes" : "no";
        const isActiveText = item.isActive ? t("ui.yes") : t("ui.no");

        const result = {
          id: item.id,
          name: item.name,
          duration: item.duration,
          cost: item.cost,
          isActive: isExport ? isActiveText : <Status content={isActiveText} type={isActiveType} />,
          thumbnailId: item.thumbnailId,
          badgeId: item.badgeId,
          createdAt: moment(item.createdAt).format("MM.DD.YYYY"),
        };

        if (!isExport && options) {
          result.options = options;
          return result;
        }

        result.__typename = item.__typename;
        return result;
      });
    },
    [t]
  );

  return {
    modifyData,
  };
};

export default useModifiers;
