import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../constants";
import ProductMediaItem from "./ProductMediaItem";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import archiveMediaRecordMutation from "../graphql/media-gallery/archiveMediaRecordMutation";
import { RemoveMedia } from "./Dialogs";
import Dialog from "../../../UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import MediaUploader from "./MediaUploader";
import { useParams } from "react-router";
import { useFetchData } from "../hooks/useFetchData";

const WrapperColumns = styled("div")`
  display: flex;
  gap: 32px;

  p {
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.black};
    margin: 0;
  }
`;

const ThumbnailMediaGallery = ({
  dialogOpen,
  setDialogOpen,
  thumbnailIdRef,
  passesTypeImage,
  setIsDisable,
}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [isUploading, setIsUploading] = useState(false);

  const [thumbnailMedia, setThumbnailMedia] = useState([]);

  const { passId } = useParams();
  const fetchData = useFetchData();

  useEffect(() => {
    if (!passId) return;
    fetchData(passId, thumbnailIdRef, passesTypeImage)
      .then(thumbnails => {
        setThumbnailMedia([thumbnails]);
        setIsDisable(prev => ({ ...prev, thumbnailId: false }));
      })
      .catch(error => {
        console.error("Failed to load thumbnails:", error);
      });
  }, [t, passId]);

  const handleDeleteImage = useCallback(
    id => {
      localStorage.setItem("imageId", JSON.stringify(id));
      setDialogOpen(true);
    },
    [thumbnailMedia, setThumbnailMedia]
  );

  const handleRemoveMedia = useCallback(() => {
    const item = localStorage.getItem("imageId");
    const id = JSON.parse(item);
    setDialogOpen(false);

    apolloClient
      .mutate({
        mutation: archiveMediaRecordMutation,
        variables: {
          input: {
            mediaRecordId: id,
            shopId: "b9XYSK5LcqtNf2Ltw",
          },
        },
      })
      .then(() => {
        setThumbnailMedia([]);
        setIsDisable(prev => ({ ...prev, thumbnailId: true }));
      })
      .catch(e => {
        enqueueSnackbar(t("product.unable_remove_media"), { variant: "error" });
        console.log(e.message);
      });
  }, [thumbnailMedia, setThumbnailMedia, t]);

  return (
    <>
      {thumbnailMedia.length ? (
        <WrapperColumns>
          <p>{t("product.media")}</p>
        </WrapperColumns>
      ) : null}
      {thumbnailMedia.map(item => (
        <ProductMediaItem
          key={item?._id}
          data={item}
          handleDeleteImage={() => handleDeleteImage(item?._id)}
        />
      ))}

      <MediaUploader
        passesTypeImage={passesTypeImage}
        enqueueSnackbar={enqueueSnackbar}
        setThumbnailMedia={setThumbnailMedia}
        setIsUploading={setIsUploading}
        isUploading={isUploading}
        thumbnailIdRef={thumbnailIdRef}
        setIsDisable={setIsDisable}
      />

      <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <RemoveMedia confirmAction={handleRemoveMedia} declineAction={() => setDialogOpen(false)} />
      </Dialog>
    </>
  );
};

export default React.memo(ThumbnailMediaGallery);
