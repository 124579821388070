import { constants } from "../../constants.js";
import { IconNavShoppers, IconNavAddPerson } from "../../icons";
import SpacesPage from "./pages/SpacesPage.js";
import ZonesPage from "./pages/ZonesPage.js";
import StreetPage from "./pages/StreetPage.js";
import CatalogPage from "./pages/CatalogPage.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  registerRoute({
    group: "navigation",
    path: "/spaces",
    priority: 80,
    MainComponent: SpacesPage,
    IconComponent: IconNavShoppers,
    navigationItemLabel: "menu.spaces",
    showSingleShopSelector: false,
    collapse: {
      title: "menu.spaces",
      IconComponent: IconNavShoppers,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    group: "navigation",
    path: "/zone",
    priority: 81,
    MainComponent: ZonesPage,
    IconComponent: IconNavAddPerson,
    navigationItemLabel: "menu.zones",
    showSingleShopSelector: false,
    collapse: {
      title: "menu.spaces",
      IconComponent: IconNavShoppers,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    group: "navigation",
    path: "/streets",
    priority: 82,
    MainComponent: StreetPage,
    IconComponent: IconNavAddPerson,
    navigationItemLabel: "menu.streets",
    showSingleShopSelector: false,
    collapse: {
      title: "menu.spaces",
      IconComponent: IconNavShoppers,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    path: "/street/catalog/:streetsId",
    MainComponent: CatalogPage,
    showShopSelector: false,
  });

  registerRoute({
    path: "/zone/catalog/:zonesId",
    MainComponent: CatalogPage,
    showShopSelector: false,
  });

  registerRoute({
    path: "/spaces/catalog/:spacesId",
    MainComponent: CatalogPage,
    showShopSelector: false,
  });
}
