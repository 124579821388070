import React from "react";
import styled from "styled-components";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import IconButtons from "../../../UI/IconButtons";
import { useTranslation } from "react-i18next";

const WrapperMediaItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 24px 0;

  .image {
    width: 150px;
    height: 150px;
    margin: 0 27px;
  }

  .feature {
    flex-grow: 1;
  }
`;

const WrapperIMG = styled.img`
  max-height: 150px;
  max-width: 150px;
`;

const MediaItem = ({ data, handleDeleteImage }) => {
  const { t } = useTranslation();

  if (!data) return;

  let imageSrc = data.imageURLs.small;

  const isValidImage = imageSrc !== "null";

  return (
    isValidImage && (
      <WrapperMediaItem>
        <div className="image">{isValidImage ? <WrapperIMG src={imageSrc} alt="img" /> : null}</div>

        <div className="feature">
          <PageStatusMessage>{t("product.default")}</PageStatusMessage>
        </div>

        <IconButtons type="deleteImage" handleClick={handleDeleteImage} />
      </WrapperMediaItem>
    )
  );
};

export default React.memo(MediaItem);
