import React, { useContext, useEffect, useRef, useState } from "react";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";
import { Container } from "@material-ui/core";
import useProduct from "../hooks/useProduct";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ProductSchema } from "../schemas";
import { Form } from '../../../UI/Form/MyForm';
import BlockMain from "../blocks/BlockMain";
import StyledButton from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import BlockSide from "../blocks/BlockSide";
import Grid from "@material-ui/core/Grid";
import BlockDetails from "../blocks/BlockDetails";
import { slugify } from "transliteration";
import { useMutation } from "@apollo/react-hooks";
import { updateProductCategoriesMutation } from "../../../package/src/CategorySelectorWithData/graphql/mutations";
import { updateProductBrandsMutation } from "../../../package/src/BrandSelectorWithData/graphql/mutations";
import BlockAttributes from "../blocks/BlockAttributes";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import Dialog from "../../../UI/Dialog/Dialog";
import createProductMutation from "../graphql/mutations/createProduct";
import { useTranslation } from "react-i18next";
import { ProductVariantsContext } from "../../../package/src/context/ProductVariantsContext";
import { cloneDeep } from "lodash";
import buildAttributesByVariants from "../helpers/buildAttributesByVariants";
import mapVariants from "../helpers/mapVariants";
import includeNumber from "../helpers/includeNumber";

const defaultValues = {
  // block 'main'
  productName: '',
  productSubtitle: '',
  productShortDescription: '',
  productLongDescription: '',
  productNotes: '',

  // block 'side'
  status: 'invisible',
  categories: [],
  facebook: '',
  twitter: '',
  direct: '',
  tags: [],

  // block 'details'
  brand: null,
  originCountry: '',
  price: 0,
  compareAtPrice: 0,
  sku: '',
  enableInventory: false,
  allowBackorders: false,
  quantity: 0,

  // block 'attributes'

  width: '',
  height: '',
  length: '',
  dimensionsUnit: 'cm',
  weight: '',
  weightUnit: 'kg',
  productTypeId: '',
  relatedProducts: [],
}

const ProductDetail = ({ match, history, viewer }) => {
  const shopId = match?.params.shopId;
  const resetFormRef = useRef(true); // solves the problem with updating form fields after updating images
  const refSubmit = useRef(null);
  const productId = match?.params.productId;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(ProductSchema),
    defaultValues,
  });

  const {
    onSetVariantAttributes,
    onSetOriginVariantParams,
    onSetVariants,
    onSetOriginVariants,
    onSetAttributeValues,
    onSetMedia,
  } = useContext(ProductVariantsContext);

  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
    getValues,
  } = methods;

  useEffect(() => {
    // solves the problem with the enabled "save changes" button after submit
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  const {
    productTypes,
    product,
    onPublishProduct,
    onUpdateProduct,
    onUpdateRelatedProducts,
    refetchProduct,
    refetchMedia,
    mediaData,
    isLoading: loading,
    isPublished,
  } = useProduct({ productId, shopId });

  const handleRefetchProduct = () => {
    if (refetchProduct) {
      resetFormRef.current = true;
      refetchProduct();
    }
  };

  const handleRefetchMedia = () => {
    if (refetchMedia) {
      resetFormRef.current = false;
      refetchMedia();
    }
  };

  const [createProduct] = useMutation(createProductMutation, {
    onError: (e) => console.log(e.message),
  });

  const [mutateProductCategories] = useMutation(updateProductCategoriesMutation, {
    ignoreResults: true,
    onError: (e) => console.log(e.message),
  });

  const [mutateProductBrands] = useMutation(updateProductBrandsMutation, {
    ignoreResults: true,
    onError: (e) => console.log(e.message),
  });

  useEffect(() => {
    if (mediaData) {
      onSetMedia(mediaData);
    }
  }, [mediaData]);

  useEffect(() => {
    if (!resetFormRef.current || !product) {
      return;
    }

    onSetVariantAttributes(product.productTypeObject?.variantAttributes || []);

    // form data

    const variantOptions = {
      price: product.variantOptions?.pricing.price || 0,
      compareAtPrice: product.variantOptions?.pricing.compareAtPrice || 0,
      sku: product.variantOptions?.sku || "",
      width: product.variantOptions?.dimensions.width || "",
      height: product.variantOptions?.dimensions.height || "",
      length: product.variantOptions?.dimensions.length || "",
      weight: product.variantOptions?.weight.value || "",
      dimensionsUnit: product.variantOptions?.dimensions.unit || "cm",
      weightUnit: product.variantOptions?.weight.unit || "kg",
      quantity: product.variantOptions?.inventory.quantity || 0,
    };

    reset({
      productName: product.title || "",
      productSubtitle: product.pageTitle || "",
      productShortDescription: product.metaDescription || "",
      productLongDescription: product.description || "",
      productNotes: product.purchaseNotes || "",
      status: product.isVisible ? "visible" : "invisible",
      categories: product.productCategories.map(item => item._id),
      facebook: product.facebookMsg,
      twitter: product.twitterMsg,
      direct: product.directMsg,
      tags: product.tags.nodes.map(item => item._id),
      brand: product.productBrands[0]?._id || null,
      originCountry: product.originCountry || "",
      relatedProducts: product.relatedProducts.map(item => item._id),
      productTypeId: product.productTypeObject?.id || "",
      enableInventory: Boolean(product.variantOptions?.inventory.isEnabled),
      allowBackorders: Boolean(product.variantOptions?.inventory.canBackorder),
      ...variantOptions,
    });

    // default parameters for generating new variants
    // if the user has changed one of these parameters, then you need to call onUpdateOriginVariantParam to keep the data up to date

    onSetOriginVariantParams({
      barcode: '', // always empty
      media: [], // always empty
      ...variantOptions,
    });

    // mapping variants

    const variantsTable = mapVariants(product.variants);
    onSetVariants(variantsTable);
    const clonedVariants = cloneDeep(variantsTable);
    onSetOriginVariants(clonedVariants);
    onSetAttributeValues(buildAttributesByVariants(clonedVariants));
  }, [product, productId])

  const onSubmit = async(formData) => {
    // const shouldConformSitemapGenerate =
    //   formData.shouldAppearInSitemap !== product.shouldAppearInSitemap &&
    //   formData.isVisible &&
    //   !formData.isDeleted;

    setIsSubmitting(true);

    const companyName = viewer?.adminUIShops.find(shop => shop._id === shopId)?.company?.name;
    let myProductId = productId;
    let needReloadPage = false;

    if (!myProductId) {
      const { data } = await createProduct({
        variables: {
          input: {
            shopId,
            product: {
              isVisible: false,
              productTypeId: formData.productTypeId,
            },
            shouldCreateFirstVariant: false,
          },
        },
      });

      if (data) {
        myProductId = data.createProduct.product._id;
        needReloadPage = true;
      }
    }

    if (!myProductId) {
      setIsSubmitting(false);
      console.error('Error while creating product');
      return;
    }

    Promise.all([
      onUpdateProduct({
        product: {
          title: formData.productName.trim(),
          pageTitle: formData.productSubtitle,
          metaDescription: formData.productShortDescription,
          description: formData.productLongDescription,
          purchaseNotes: formData.productNotes,
          isVisible: formData.status === 'visible',
          facebookMsg: formData.facebook,
          twitterMsg: formData.twitter,
          directMsg: formData.direct,
          tagIds: formData.tags,
          slug: slugify(formData.productName),
          vendor: companyName || '',
          originCountry: formData.originCountry,
        },
        productId: myProductId,
        variantOptions: {
          inventory: {
            quantity: formData.quantity || 0,
            isEnabled: formData.enableInventory,
            canBackorder: formData.allowBackorders,
          },
          sku: formData.sku ? formData.sku.trim() : undefined,
          pricing: {
            price: formData.price,
            compareAtPrice: formData.compareAtPrice,
          },
          dimensions: {
            unit: formData.dimensionsUnit,
            height: includeNumber(formData.height),
            width: includeNumber(formData.width),
            length: includeNumber(formData.length),
          },
          weight: {
            unit: formData.weightUnit,
            value: includeNumber(formData.weight),
          },
        },
      }),

      mutateProductCategories({
        variables: {
          productId: myProductId,
          shopId,
          productCategoryIds: formData.categories,
        },
      }),

      mutateProductBrands({
        variables: {
          productId: myProductId,
          shopId,
          productBrandIds: [formData.brand],
        },
      }),

      onUpdateRelatedProducts({
        productId: myProductId,
        relatedProductIds: formData.relatedProducts,
      }),
    ])
      .then(() => {
        if (needReloadPage) {
          history.push(`/products/edit/${shopId}/${myProductId}`);
        } else {
          handleRefetchProduct();
          setIsSubmitting(false);
        }

        // if (shouldConformSitemapGenerate) {
        //   openGenerateSitemapsConfirmDialog();
        // }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
      })
  }

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmitWithinDialog = () => {
    refSubmit?.current.click();
    handleCloseDialog();
  };

  return (
    <>
      <PrimaryAppBar
        title={productId ? t('product.edit_product') : t('product.create_product')}
        onBackButtonClick={() => {
          history.push('/products');
        }}
      />

      <Container>
        <FormProvider
          {...methods}
          loading={loading || isSubmitting}
          product={product}
          handleOpenDialog={handleOpenDialog}
        >
          <Form
            width='100%'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={7}>
                <BlockMain />
                <BlockDetails
                  productId={productId}
                  shopId={shopId}
                  refetchMedia={handleRefetchMedia}
                />
                <BlockAttributes
                  productId={productId}
                  shopId={shopId}
                  productTypes={productTypes}
                  refetchMedia={handleRefetchMedia}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <BlockSide
                  shopId={shopId}
                />
              </Grid>
            </Grid>

            <SaveButtons margin='24px 0' justifyContent='flex-end'>
              <StyledButton
                width='180px'
                type="submit"
                disabled={!isDirty || loading || isSubmitting}
                ref={refSubmit}
              >
                {productId ? t('ui.save_changes') : t('product.create_product')}
              </StyledButton>
              <StyledButton
                width='180px'
                mytype='third'
                disabled={loading || isPublished || isSubmitting}
                handleClick={onPublishProduct}
              >
                {isPublished ? t('product.published') : t('ui.publish')}
              </StyledButton>
            </SaveButtons>
          </Form>
        </FormProvider>
      </Container>

      <Dialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
      >
        <DialogMessages
          title={t('ui.warning')}
          message={t('product.you_must_create_product')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={handleCloseDialog}
          >
            {t('ui.no')}
          </StyledButton>
          <StyledButton
            width='180px'
            handleClick={handleSubmitWithinDialog}
          >
            {t('ui.yes')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </>
  );
}

export default ProductDetail;
