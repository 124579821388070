import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import moment from "moment/moment";

const useStyles = makeStyles(theme => ({
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    textDecorationColor: theme.palette.grey[500],
    textDecorationThickness: "1px",
  },
}));

const useModifiers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const spaceModifyData = useCallback(
    data => {
      return data.map(item => {
        const result = {
          id: (
            <a className={classes.link} onClick={() => history.push(`/spaces/catalog/${item.id}`)}>
              {item.id}
            </a>
          ),
          name: item.name,
          code: item.code,
          type: item.type,
          zone: item.zone.name,
          zoneId: item.zoneId,
          createdAt: moment(item.createdAt).format("MM.DD.YYYY"),
        };
        return result;
      });
    },
    [t]
  );

  const ZoneModifyData = useCallback(
    data => {
      return data.map(item => {
        const result = {
          id: (
            <a className={classes.link} onClick={() => history.push(`/zone/catalog/${item.id}`)}>
              {item.id}
            </a>
          ),
          name: item.name,
          defaultSpaceId: item.defaultSpaceId,
          defaultSpace: item.defaultSpace?.name,
        };
        return result;
      });
    },
    [t]
  );

  const streetModifyData = useCallback(
    data => {
      return data.map(item => {
        const result = {
          id: (
            <a className={classes.link} onClick={() => history.push(`/street/catalog/${item.id}`)}>
              {item.id}
            </a>
          ),
          name: item.name,
          type: item.type,
          slug: item.slug,
          zone: item.zone.name,
          zoneId: item.zoneId,
        };
        return result;
      });
    },
    [t]
  );

  return { streetModifyData, ZoneModifyData, spaceModifyData };
};

export default useModifiers;
