import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import FormCard from "../../../../UI/Form/FormCard";
import { Form } from "../../../../UI/Form/MyForm";
import HorizontalRule from "../../../../UI/other/HorizontalRule";
import Button from "../../../../UI/Button/Button";
import SaveButtons from "../../../../UI/Button/SaveButtons";
import { Auth } from "aws-amplify";
import FormInputPhoneNumber from "../../../../UI/Form/FormInputPhoneNumber";
import FormHeader from "../../../../UI/Form/FormHeader";
import { SettingsGeneralSchema } from "../schemas";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../../package/src/DatePicker";
import { AuthContext } from "../../../../package/src/context/AuthContext";
import moment from "moment";

const ProfileGeneral = ({ viewer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(SettingsGeneralSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      phoneNumber: "",
    },
  });

  useEffect(() => {
    async function fetchAttributes() {
      if (!user) {
        return;
      }

      const attributes = await Auth.userAttributes(user);

      if (!attributes) {
        return;
      }

      const attrObj = {};
      attributes.forEach(item => {
        attrObj[item.Name] = item.Value;
      });
      const result = {};
      result.firstName = attrObj.given_name || "";
      result.lastName = attrObj.family_name || "";
      result.email = attrObj.email || "";
      result.phoneNumber = attrObj.phone_number || "";
      result.dateOfBirth = attrObj.birthdate || "";
      reset(result);
    }

    fetchAttributes()
      .catch(e => {
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refetch]);

  const onSubmit = async formData => {
    if (!user) {
      return;
    }

    const { phoneNumber, email, firstName, lastName, dateOfBirth } = formData;
    setLoading(true);

    try {
      const res2 = await Auth.updateUserAttributes(user, {
        given_name: firstName,
        family_name: lastName,
        phone_number: phoneNumber,
        email,
        birthdate: moment(formData.dateOfBirth).format("YYYY-MM-DD"),
      });

      if (res2) {
        enqueueSnackbar(t("snackbar.update_success"), { variant: "success" });
        setRefetch(prev => !prev);
      }
    } catch (e) {
      const message = e.message;

      if (typeof message === "string" && message.indexOf("Invalid phone number format") > -1) {
        setError("phoneNumber", { message });
        return;
      }

      enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
      console.log(message);
      setLoading(false);
    }
  };

  const handleDecline = () => {
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} width="100%">
      <FormCard>
        <FormHeader>{t("settings.general")}</FormHeader>
        <InputLabel disabled={loading} error={errors.firstName}>
          {t("settings.first_name")}
        </InputLabel>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <FormInput
              placeholder={t("settings.write_your_name")}
              value={field.value}
              onChange={field.onChange}
              error={errors.firstName}
              disabled={loading}
            />
          )}
        />
        <InputLabel disabled={loading} error={errors.lastName}>
          {t("settings.last_name")}
        </InputLabel>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <FormInput
              placeholder={t("settings.write_your_surname")}
              value={field.value}
              onChange={field.onChange}
              error={errors.lastName}
              disabled={loading}
            />
          )}
        />
        <InputLabel error={errors.dateOfBirth} disabled={loading}>
          {t("settings.date_of_birth")}
        </InputLabel>
        <Controller
          name="dateOfBirth"
          control={control}
          render={({ field }) => (
            <CustomDatePicker
              margin="0 0 24px"
              placeholder={t("settings.choose_date_of_birth")}
              value={field.value}
              onChange={field.onChange}
              error={errors.dateOfBirth}
              disabled={loading}
            />
          )}
        />
        <InputLabel disabled={loading} error={errors.email}>
          {t("settings.email")}
        </InputLabel>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <FormInput
              placeholder={t("settings.write_email")}
              value={field.value}
              onChange={field.onChange}
              error={errors.email}
              disabled={loading}
            />
          )}
        />
        <InputLabel disabled={loading} error={errors.phoneNumber}>
          {t("settings.phone_number")}
        </InputLabel>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <FormInputPhoneNumber
              placeholder={t("settings.write_phone_number")}
              value={field.value}
              onChange={field.onChange}
              error={errors.phoneNumber}
              disabled={loading}
              phoneError={phoneError}
              setPhoneError={setPhoneError}
            />
          )}
        />
        <HorizontalRule />

        <SaveButtons>
          <Button width="50%" type="submit" disabled={!isDirty || loading || Boolean(phoneError)}>
            {t("ui.save")}
          </Button>
          <Button
            width="50%"
            type="button"
            mytype="third"
            disabled={!isDirty || loading}
            handleClick={handleDecline}
          >
            {t("ui.cancel")}
          </Button>
        </SaveButtons>
      </FormCard>
    </Form>
  );
};

export default ProfileGeneral;
