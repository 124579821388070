import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "../../../UI/Form/MyForm";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { PassesSchema } from "../schemas";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import Button from "../../../UI/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import { createPass, updatePass } from "../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { Container } from "@material-ui/core";
import FormCard from "../../../UI/Form/FormCard";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";
import ThumbnailMediaGallery from "../components/ThumbnailMediaGallery";
import BadgeMediaGallery from "../components/BadgeMediaGallery";
import { useParams } from "react-router";
import { getOnePass } from "../graphql/queries";
import FormNote from "../../../UI/Form/FormNote";
import PassRestrictedSpaces from "../components/PassRestrictedSpaces";

const StyledContainer = styled(Container)`
  max-width: 1100px;
`;

const FormPassInformation = () => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const badgeIdRef = useRef({ id: "" });
  const thumbnailIdRef = useRef({ id: "" });
  const [isCanAddSpace, setCanAddSpace] = useState(false);
  const [dialogOpenThumbnail, setDialogOpenThumbnail] = useState(false);
  const [dialogOpenBadge, setDialogOpenBadge] = useState(false);
  const [isDisable, setIsDisable] = useState({ badgeId: true, thumbnailId: true });

  const { passId } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(PassesSchema),
    defaultValues: {
      name: t("passes.new_pass"),
      duration: "",
      cost: "",
      isActive: false,
    },
  });

  useEffect(() => {}, []);

  const getPass = async passId => {
    if (!passId) {
      return Promise.resolve();
    }
    return apolloClient.query({
      query: getOnePass,
      variables: {
        id: passId,
      },
      fetchPolicy: "network-only",
    });
  };

  const mutatePass = useCallback(
    payload => {
      if (!passId) {
        setCanAddSpace(true);
        return apolloClient.mutate({
          mutation: createPass,
          variables: {
            input: payload,
          },
        });
      }

      return apolloClient.mutate({
        mutation: updatePass,
        variables: {
          input: payload,
        },
      });
    },
    [passId]
  );

  useEffect(() => {
    if (!passId) {
      setLoading(false);
      return;
    }
    getPass(passId)
      .then(res => {
        const data = res.data.pass.data;

        if (data) {
          reset({
            name: data.name,
            duration: data.duration,
            cost: data.cost,
            isActive: Boolean(data.isActive),
          });
        }
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      })
      .finally(() => setLoading(false));
  }, [t, passId]);

  const onSubmit = useCallback(
    newData => {
      setLoading(true);

      const payload = {
        ...(passId && { id: passId }),
        name: newData.name,
        duration: newData.duration,
        cost: newData.cost,
        thumbnailId: thumbnailIdRef.current.id,
        badgeId: badgeIdRef.current.id,
        isActive: Boolean(newData.isActive),
      };

      mutatePass(payload)
        .then(response => {
          enqueueSnackbar(
            passId ? t("passes.passes_edited_success") : t("passes.passes_created_success"),
            { variant: "success" }
          );
          if (passId) {
            history.push("/passes");
          } else {
            const createdPassId = response.data.createPass.data.id;
            history.push(`/passes/edit/${createdPassId}`);
          }
          setLoading(false);
        })
        .catch(e => {
          enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
          console.log(e.message);
          setLoading(false);
        });
    },
    [mutatePass, t]
  );

  const handleNumberChange = (e, func, isInt) => {
    let value = e.target.value;
    if (value.length > 6) {
      value = value.slice(0, 6);
    }
    if (isInt) {
      if (/^\d*$/.test(value)) {
        func(value);
      }
    } else {
      if (Number(value) < 0) value = "0";
      func(value);
    }
  };

  return (
    <StyledContainer>
      <PrimaryAppBar
        title={passId ? t("passes.edit_pass") : t("passes.add_pass")}
        onBackButtonClick={() => {
          history.push("/passes");
        }}
      />
      <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <FormCard>
              <FormHeader>{t("passes.details")}</FormHeader>
              <CheckboxContainer>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={field.value}
                        onChange={e => {
                          field.onChange(e.target.checked);
                        }}
                        disabled={loading}
                      />
                    );
                  }}
                />
                {t("passes.isActive")}
              </CheckboxContainer>

              <InputLabel disabled={loading}>{t("passes.name")}</InputLabel>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <FormInput
                    placeholder={t("passes.write_name_here")}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.name}
                    disabled={loading}
                    maxLength={250}
                    type="text"
                  />
                )}
              />
            </FormCard>
          </Grid>

          <Grid item xs={12} lg={5}>
            <FormCard>
              <FormHeader>{t("passes.details")}</FormHeader>

              <InputLabel error={errors.cost} disabled={loading}>
                {t("passes.cost")}
              </InputLabel>
              <Controller
                control={control}
                name="cost"
                render={({ field }) => (
                  <FormInput
                    style={{ marginBottom: "10px" }}
                    placeholder={t("passes.write_cost_here")}
                    value={field.value}
                    onChange={e => handleNumberChange(e, field.onChange, true)}
                    error={errors.cost}
                    disabled={loading}
                    maxLength={10}
                    type="text"
                  />
                )}
              />
              <FormNote style={{ marginTop: 0 }}>{t("errors.max_6_digits")}</FormNote>
              <InputLabel
                error={errors.duration}
                optionalText={t("subscription.in_days")}
                disabled={loading}
              >
                {t("passes.duration")}
              </InputLabel>
              <Controller
                control={control}
                name="duration"
                render={({ field }) => (
                  <FormInput
                    style={{ marginBottom: "10px" }}
                    placeholder={t("passes.duration")}
                    value={field.value}
                    onChange={e => handleNumberChange(e, field.onChange, true)}
                    type="text"
                    error={errors.duration}
                    disabled={loading}
                  />
                )}
              />
              <FormNote style={{ marginTop: 0 }}>{t("errors.max_6_digits")}</FormNote>
            </FormCard>
          </Grid>
        </Grid>

        <FormCard>
          <FormHeader>
            <InputLabel disabled={loading}>{t("passes.thumbnailId")}</InputLabel>
          </FormHeader>
          <ThumbnailMediaGallery
            key="thumbnailId"
            thumbnailIdRef={thumbnailIdRef}
            passesTypeImage="thumbnailId"
            dialogName="thumbnailId"
            dialogOpen={dialogOpenThumbnail}
            setIsDisable={setIsDisable}
            setDialogOpen={setDialogOpenThumbnail}
          />
        </FormCard>

        <FormCard>
          <FormHeader>
            <InputLabel disabled={loading}>{t("passes.badgeId")}</InputLabel>
          </FormHeader>
          <BadgeMediaGallery
            key="badgeId"
            badgeIdRef={badgeIdRef}
            setIsDisable={setIsDisable}
            passesTypeImage="badgeId"
            dialogName="badgeId"
            setDialogOpen={setDialogOpenBadge}
            dialogOpen={dialogOpenBadge}
          />
        </FormCard>

        <SaveButtons margin="40px 0 " justifyContent="flex-end">
          <Button
            disabled={loading || !isValid || isDisable.badgeId || isDisable.thumbnailId}
            type="submit"
            width="180px"
          >
            {passId ? t("ui.save_changes") : t("ui.create")}
          </Button>
        </SaveButtons>
      </Form>
      <PassRestrictedSpaces isCanAddSpace={isCanAddSpace} />
    </StyledContainer>
  );
};

export default FormPassInformation;
