import gql from "graphql-tag";

const Pass = `
  id
  name
  duration
  cost
  isActive
  thumbnailId
  thumbnailUrl
  badgeId
  badgeUrl
  createdAt
  updatedAt
`;

export const getAllSpaces = gql`
  query spaces($input: SpacesQueryInput) {
    spaces(input: $input) {
      paging {
        total
      }
      data {
        id
        name
        code
        type
        zoneId
        createdAt
        isRestricted
        restrictedToIds
        restrictedTo{
          ${Pass}
      }
        zone {
          id
          name
          defaultSpace {
            id
            name
            code
            type
          }
        }
      }
    }
  }
`;

export const getAllZones = gql`
  query zones {
    zones {
      data {
        id
        name
        defaultSpaceId
        defaultSpace {
          id
          name
          code
          type
          zone {
            id
            name
            defaultSpace {
              id
              name
              code
              type
            }
          }
        }
      }
    }
  }
`;

export const getAllStreets = gql`
  query availableStreets {
    availableStreets {
      data {
        id
        name
        type
        slug
        zoneId
        zone {
          id
          name
          defaultSpaceId
        }
        categories
        places {
          id
          status
          holder {
            holderId
            holderName
            holderType
          }
        }
      }
    }
  }
`;

export const getZone = gql`
  query zone($id: ID!) {
    zone(id: $id) {
      data {
        id
        name
        defaultSpaceId
        defaultSpace {
          id
          name
          code
          type
          zoneId
          zone {
            id
            name
          }
        }
      }
    }
  }
`;

export const getStreet = gql`
  query street($streetId: String!) {
    street(streetId: $streetId) {
      id
      name
      type
      slug
      zoneId
      space {
        id
        name
        type
      }
      zone {
        id
        name
        defaultSpaceId
      }
      categories
    }
  }
`;
