import gql from "graphql-tag";
import { Pass } from "./fragments";

export const createPass = gql`
  mutation createPass($input: CreatePassInput) {
    createPass(input: $input) {
      data {
        ${Pass}
      }
    }
  }
`;

export const updatePass = gql`
  mutation updatePass($input: UpdatePassInput) {
    updatePass(input: $input) {
      data {
        ${Pass}
      }
    }
  }
`;

export const removePass = gql`
  mutation removePass($input: RemovePassInput) {
    removePass(input: $input) {
      data {
        ${Pass}
      }
    }
  }
`;
