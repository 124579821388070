import { css, styled } from "@mui/material";
import { LanguageContext } from "../../src/context/LanguageContext.js";
import { useContext } from "react";

const StyledError = styled("p")`
  color: #df5656;
  margin: 4px 0 10px 0;
  font-family: "Mulish", sans-serif;
  font-size: 13px;

  @media all and (max-width: 768px) {
    margin: 6px 0 10px 0;
  }

  ${props =>
    props.isrtl === "true" &&
    css`
      margin: 8px 0 10px 0;
      @media all and (max-width: 768px) {
        margin: 6px 0 10px 0;
      }
    `}
`;

const ErrorLine = ({ children, isAbsolute, sx }) => {
  const { isRtl } = useContext(LanguageContext);

  return (
    <StyledError
      style={{
        position: isAbsolute ? "absolute" : "static",
        ...sx,
      }}
      isrtl={String(isRtl)}
    >
      {children}
    </StyledError>
  );
};

export default ErrorLine;
