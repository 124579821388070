import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { css, styled, TextField } from "@mui/material";
import { forwardRef, Ref, useContext } from "react";
import ErrorLine from "./ErrorLine.js";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../src/context/LanguageContext.js";

const dateTimePaperPropsStyles = {
  sx: {
    ".MuiPickersArrowSwitcher-button": {
      transform: "rotate(180deg)",
    },
  },
};

const StyledTextField = styled(TextField)`
  background: #f3f3f3;
  border-radius: 50px;
  width: 100%;
  margin-bottom: 6px;

  & .MuiInputBase-input {
    width: 100%;
    border: 0;
    height: 20px;
    background: #f3f3f3;
    border-radius: 50px;
    padding: 13px 22px;
    font-family: Mulish, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
  }

  .MuiInputAdornment-root {
    position: absolute;
    right: 22px;
    top: 50%;
  }

  .Mui-error {
    border: 1px solid #df5656;
    border-radius: 50px;
    padding: 0;
  }

  input::placeholder {
    font-family: Mulish, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #bfbfbf;
  }

  & .MuiInput-underline:after {
    border-width: 0;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-width: 0;
    }

    &:hover fieldset {
      border-width: 0;
    }

    &.Mui-focused fieldset {
      border-width: 0;
    }
  }

  @media all and (max-width: 768px) {
    margin-bottom: 20px;
  }

  ${props =>
    props.isrtl === "true" &&
    css`
      .MuiInputAdornment-root {
        right: unset;
        left: 0;
        position: relative;
      }
    `}
`;

const CustomDatePicker = ({ value, onChange, error, disabled }, ref) => {
  const { t } = useTranslation();
  const { isRtl } = useContext(LanguageContext);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} ref={ref}>
      <DatePicker
        value={value}
        PaperProps={isRtl ? dateTimePaperPropsStyles : {}}
        onChange={onChange}
        renderInput={textFieldProps => (
          <StyledTextField
            {...textFieldProps}
            error={Boolean(error)}
            disabled={disabled}
            isrtl={String(isRtl)}
          />
        )}
        inputFormat="DD/MM/YYYY"
      />
      {error?.type === "dateOfBirth" ? <ErrorLine>{t(`${error.message}`)}</ErrorLine> : null}
    </LocalizationProvider>
  );
};

export default forwardRef(CustomDatePicker);
