import { useCallback, useState, useEffect } from "react";
import { useApolloClient } from "react-apollo";
import { useSnackbar } from "notistack";

import { getZone, getAllSpaces, getStreet } from "../graphql/queries";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

export const useFetchData = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const apolloClient = useApolloClient();

  const [data, setData] = useState(null);

  const { pathname } = location;
  const pathParts = pathname.split("/");
  const entityType = pathParts[1];
  const entityId = pathParts[3];

  const queryMap = {
    spaces: getAllSpaces,
    street: getStreet,
    zone: getZone,
  };

  const idMap = {
    spaces: "id",
    street: "streetId",
    zone: "id",
  };

  const fetchData = useCallback(() => {
    const query = queryMap[entityType];
    const id = idMap[entityType];
    let variables = {};

    if (!query) {
      enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
      return;
    }

    if (entityType === "spaces") {
      variables = { input: { filters: { [id]: entityId } } };
    } else {
      variables = { [id]: entityId };
    }

    apolloClient
      .query({
        query,
        variables,
        fetchPolicy: "network-only",
      })
      .then(res => {
        const responseData = res.data[entityType];
        setData(replaceData(responseData, entityType));
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.error(e.message);
      })
  }, [entityType, entityId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data };
};

function replaceData(responseData, entityType) {
  let finalData = {};
  if (Array.isArray(responseData.data)) {
    finalData = responseData.data[0];
  } else {
    finalData = responseData.data || responseData;
  }
  finalData.queryType = entityType;
  return finalData;
}
