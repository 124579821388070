import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dropzone from "../../../UI/Dropzone";
import DropContainer from "../../../UI/Dropzone/DropContainer";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import createMediaRecordMutation from "../graphql/media-gallery/createMediaRecordMutation";
import { FileRecord } from "@reactioncommerce/file-collections";
import { useTranslation } from "react-i18next";
import { mediaRecordsQuery } from "../graphql/media-gallery/queries";

const MediaUploader = ({
  passesTypeImage,
  setThumbnailMedia,
  setBadgeMedia,
  thumbnailIdRef,
  badgeIdRef,
  enqueueSnackbar,
  isUploading,
  setIsUploading,
  setIsDisable,
  onFiles,
}) => {
  const { t } = useTranslation();

  const [createMediaRecord] = useMutation(createMediaRecordMutation, {
    ignoreResults: true,
  });
  const apolloClient = useApolloClient();

  const uploadFiles = filesArray => {
    setIsUploading(true);

    const promises = filesArray.map(async (browserFile, index) => {
      const newFileName = browserFile.name.replace(")", "").replace("(", "");
      const myNewFile = new File([browserFile], newFileName, { type: browserFile.type });
      const fileRecord = FileRecord.fromFile(myNewFile);

      let newPriority = 1 + index;
      if (newPriority > 99) {
        newPriority = "";
      }

      fileRecord.metadata = {
        type: "pass",
      };

      await fileRecord.upload();

      createMediaRecord({
        variables: {
          input: {
            mediaRecord: fileRecord.document,
            shopId: "b9XYSK5LcqtNf2Ltw",
          },
        },
      })
        .then(response => {
          const uploadedMediaIds = response.data.createMediaRecord.mediaRecord._id;
          if (passesTypeImage === "badgeId") {
            if (badgeIdRef.current) {
              badgeIdRef.current.id = uploadedMediaIds;
            }
          } else {
            if (thumbnailIdRef.current) {
              thumbnailIdRef.current.id = uploadedMediaIds;
            }
          }
          let isProcessed = false;
          const timerId = setInterval(async () => {
            apolloClient
              .query({
                query: mediaRecordsQuery,
                variables: {
                  shopId: "b9XYSK5LcqtNf2Ltw",
                  mediaRecordIds: uploadedMediaIds,
                },
                fetchPolicy: "network-only",
              })
              .then(res => {
                if (isProcessed) {
                  setIsUploading(false);
                  clearTimeout(timerId);
                }
                if (passesTypeImage === "badgeId") {
                  setBadgeMedia([res.data.mediaRecords.nodes[0]]);
                  setIsDisable(prev => ({ ...prev, badgeId: false }));
                } else {
                  setThumbnailMedia([res.data.mediaRecords.nodes[0]]);
                  setIsDisable(prev => ({ ...prev, thumbnailId: false }));
                }
                return res;
              });
          }, 2000);

          setTimeout(() => {
            clearTimeout(timerId);
            setIsUploading(false);
          }, 10000);
        })

        .catch(error => {
          setIsUploading(false);
          enqueueSnackbar(t("product.image_upload_error"), { variant: "error" });
          console.log(error.message);
        });
    });
  };

  const handleDrop = files => {
    if (files.length === 0) return;
    if (onFiles) {
      onFiles(files);
    } else {
      uploadFiles(files);
    }
  };

  return (
    <>
      {isUploading ? (
        <LinearProgress />
      ) : (
        <Dropzone accept="image/jpg, image/png, image/jpeg" onDrop={handleDrop} maxFiles={10}>
          <DropContainer />
        </Dropzone>
      )}
    </>
  );
};

export default MediaUploader;
