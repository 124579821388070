import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "../../../UI/Form/MyForm";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { PackagesSchema } from "../schemas";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import Button from "../../../UI/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import { getAvailableStreets, getPackage, getShopCategoriesQuery } from "../graphql/queries";
import { addPackage, updatePackage } from "../graphql/mutations";
import FormNote from "../../../UI/Form/FormNote";
import Select from "../../../UI/Select/Select";
import Grid from "@material-ui/core/Grid";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { Container } from "@material-ui/core";
import FormCard from "../../../UI/Form/FormCard";
import { useHistory } from "react-router-dom";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import filterStreets from "../utils/filterStreets";
import { mockCurrencies, mockLocations } from "./mocks";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)`
  max-width: 1100px;
`;

const FormPackageInformation = ({ packageId }) => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty: isFormDirty, isValid, touchedFields },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(PackagesSchema),
    defaultValues: {
      title: "",
      location: "",
      description: "",
      rate: "",
      currency: "",
      period: "",
      commission: "",
      isVisible: false,
      maxRooms: "",
    },
  });

  // доступные опции категорий, обработанные
  // {value: 'iALRXETbRyjbaQsDx', label: 'Jewelry', selected: true }
  const [categoriesItems, setCategoriesItems] = useState([]);
  const [categoriesItemsInitial, setCategoriesItemsInitial] = useState([]);

  /*
    доступные опции улиц, сырые
  {
    categories: ['xgjMngakbbkr7eseY', 'zmT7JhmtLorYtjZBY'], категории, в которых появляется данная улица
    id: "21be622c-eefb-437a-957c-49b1caae5309"
    name: "Men's Fashion"
  }
   */
  const [streetsOptions, setStreetsOptions] = useState([]);

  // выбрано в данный момент, string[]
  const [streets, setStreets] = useState([]);
  const [streetsInitial, setStreetsInitial] = useState([]);

  // доступные опции улиц, обработанные
  // {value: '21be622c-eefb-437a-957c-49b1caae5309', label: 'Men's Fashion', selected: true }
  const streetsItems = useMemo(() => {
    const selectedCats = categoriesItems.filter(k => k.selected).map(v => v.value);
    const filteredStreets = filterStreets(streetsOptions, selectedCats);
    return filteredStreets.map(s => ({
      value: s.id,
      label: s.name,
      selected: streets.includes(s.id),
    }));
  }, [streetsOptions, categoriesItems, streets]);

  // ошибки { message: '' }
  const [errorCategories, setErrorCategories] = useState(null);
  const [errorStreets, setErrorStreets] = useState(null);

  // категории меняли?
  const isCategoriesDirty = useMemo(() => {
    return JSON.stringify(categoriesItems) !== JSON.stringify(categoriesItemsInitial);
  }, [categoriesItems, categoriesItemsInitial]);

  // улицы меняли?
  const isStreetsDirty = useMemo(() => {
    return JSON.stringify(streets) !== JSON.stringify(streetsInitial);
  }, [streets, streetsInitial]);

  const isDirty = isFormDirty || isCategoriesDirty || isStreetsDirty;

  useEffect(() => {
    // обработка ошибок streets, categories

    if (loading) {
      return;
    }

    if (categoriesItems.every(k => !k.selected)) {
      setErrorCategories({ message: t("subscription.select_1_category") });
      return;
    } else {
      setErrorCategories(null);
    }

    if (!streetsItems.length) {
      setErrorStreets({ message: t("subscription.no_streets_in_categories") });
      return;
    }

    if (!streets.length) {
      setErrorStreets({ message: t("errors.field_required") });
      return;
    }

    setErrorStreets(null);
  }, [loading, categoriesItems, streetsItems, streets, t]);

  useEffect(() => {
    // если список streetsItems обновился, и отличается от streets, то обновить состояние streets
    const selected = streetsItems.filter(k => k.selected).map(d => d.value);
    if (JSON.stringify(selected) !== JSON.stringify(streets)) {
      setStreets(selected);
    }
  }, [streetsItems, streets]);

  const getSubscriptionPackage = async () => {
    if (!packageId) {
      return Promise.resolve();
    }

    return apolloClient.query({
      query: getPackage,
      variables: {
        id: packageId,
      },
      fetchPolicy: "network-only",
    });
  };

  const getShopCategories = async () => {
    return apolloClient.query({
      query: getShopCategoriesQuery,
      variables: {
        first: 200,
      },
      fetchPolicy: "network-only",
    });
  };

  const getStreetsOptions = async () => {
    return apolloClient.query({
      query: getAvailableStreets,
      fetchPolicy: "network-only",
    });
  };

  const mutatePackage = useCallback(
    payload => {
      if (!packageId) {
        return apolloClient.mutate({
          mutation: addPackage,
          variables: {
            input: payload,
          },
        });
      }

      return apolloClient.mutate({
        mutation: updatePackage,
        variables: {
          id: packageId,
          input: payload,
        },
      });
    },
    [packageId]
  );

  useEffect(() => {
    Promise.all([getSubscriptionPackage(), getShopCategories(), getStreetsOptions()])
      .then(res => {
        const data = res[0]?.data.subscriptionPackage;
        const categories = res[1]?.data.getShopCategories.nodes;
        let streets = res[2]?.data.availableStreets.data;

        if (data) {
          reset({
            title: data.title,
            description: data.description || "",
            rate: data.baseRate,
            period: data.duration,
            commission: data.commission,
            isVisible: Boolean(data.isVisibleToMerchants),
            location: data.locationId || "",
            currency: data.currency || "",
            maxRooms: data.maxRooms ?? "",
          });
        }

        if (categories) {
          const items = categories.map(k => ({
            value: k._id,
            label: k.name,
            selected: data?.categoriesIds?.includes(k._id) || false,
          }));
          setCategoriesItems(items);
          setCategoriesItemsInitial(items);
        }

        if (streets) {
          setStreetsOptions(streets);
          setStreets(data?.streetsIds || []);
          setStreetsInitial(data?.streetsIds || []);
        }
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      })
      .finally(() => setLoading(false));
  }, [t]);

  const onSubmit = useCallback(
    newData => {
      setLoading(true);

      const payload = {
        title: newData.title,
        description: newData.description,
        baseRate: newData.rate,
        isVisibleToMerchants: newData.isVisible,
        duration: newData.period,
        commission: newData.commission,
        streetsIds: streets,
        locationId: newData.location,
        categoriesIds: categoriesItems.filter(k => k.selected).map(d => d.value),
        currency: newData.currency,
        maxRooms: newData.maxRooms,
      };

      mutatePackage(payload)
        .then(res => {
          enqueueSnackbar(
            packageId
              ? t("subscription.package_edited_success")
              : t("subscription.package_created_success"),
            { variant: "success" }
          );
          if (packageId) {
            // after edit
            setLoading(false);
          } else {
            // after creation
            const newPackageId = res.data.addSubscriptionPackage._id;
            history.push(`/subscription-packages/edit/${newPackageId}`);
          }
        })
        .catch(e => {
          enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
          console.log(e.message);
          setLoading(false);
        });
    },
    [mutatePackage, streets, categoriesItems, t]
  );

  const handleNumberChange = (e, func) => {
    let value = e.target.value;
    if (Number(value) < 0) value = "0";
    func(value);
  };

  const handleChangeCategories = arr => {
    // arr = [ { value, label, selected } ], массив с выбранными объектами
    setCategoriesItems(prev =>
      prev.map(k => {
        const index = arr.findIndex(v => v.value === k.value);
        return {
          ...k,
          selected: index > -1,
        };
      })
    );
  };


  const handleChangeStreets = arr => {
    // arr = [ { value, label, selected } ], массив с выбранными объектами
    setStreets(arr.map(k => k.value));
  };

  return (
    <StyledContainer>
      <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <FormCard>
              <FormHeader>{t("subscription.details")}</FormHeader>

              <CheckboxContainer>
                <Controller
                  name="isVisible"
                  control={control}
                  render={({ field }) => (
                    <Checkbox checked={field.value} onChange={field.onChange} disabled={loading} />
                  )}
                />
                {t("subscription.visible_for_merchants")}
              </CheckboxContainer>

              <InputLabel error={errors.title} disabled={loading}>
                {t("subscription.subscription_title")}
              </InputLabel>
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <FormInput
                    placeholder={t("subscription.write_subscription_title")}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.title}
                    disabled={loading}
                  />
                )}
              />

              <InputLabel optionalText={t("ui.optional")} disabled={loading}>
                {t("subscription.description")}
              </InputLabel>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <FormInput
                    placeholder={t("subscription.write_description_here")}
                    multiline
                    rows={4}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                  />
                )}
              />

              <InputLabel disabled={loading} error={errors.location}>
                {t("subscription.location")}
              </InputLabel>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder={t("subscription.choose_location")}
                    value={field.value}
                    onChange={field.onChange}
                    options={mockLocations}
                    disabled={loading}
                    error={errors.location}
                  />
                )}
              />

              <InputLabel disabled={loading} error={touchedFields.title && errorCategories}>
                {t("subscription.categories")}
              </InputLabel>
              <SelectAllAutocomplete
                placeholder={t("subscription.choose_categories")}
                selectAllLabel={t("subscription.all_categories")}
                items={categoriesItems}
                isMultiSelect
                onChange={handleChangeCategories}
                error={touchedFields.title && errorCategories}
                disabled={loading}
              />

              <InputLabel disabled={loading} error={errorStreets}>
                {t("subscription.streets")}
              </InputLabel>
              <SelectAllAutocomplete
                items={streetsItems}
                placeholder={t("subscription.choose_places")}
                selectAllLabel={t("subscription.all_places")}
                value={streets}
                onChange={handleChangeStreets}
                disabled={loading}
                error={errorStreets}
              />
              <FormNote>{t("subscription.choose_streets_hint")}</FormNote>

              <InputLabel error={errors.maxRooms} disabled={loading}>
                {t("subscription.max_rooms")}
              </InputLabel>
              <Controller
                control={control}
                name="maxRooms"
                render={({ field }) => (
                  <FormInput
                    value={field.value}
                    onChange={e => handleNumberChange(e, field.onChange)}
                    type="number"
                    error={errors.maxRooms}
                    disabled={loading}
                  />
                )}
              />
            </FormCard>
          </Grid>

          <Grid item xs={12} lg={5}>
            <FormCard>
              <FormHeader>{t("subscription.details")}</FormHeader>

              <InputLabel error={errors.rate} disabled={loading}>
                {t("subscription.rate_per_period")}
              </InputLabel>
              <Controller
                control={control}
                name="rate"
                render={({ field }) => (
                  <FormInput
                    value={field.value}
                    onChange={e => handleNumberChange(e, field.onChange)}
                    type="number"
                    error={errors.rate}
                    disabled={loading}
                  />
                )}
              />
              <FormNote style={{ marginTop: 0 }}>{t("subscription.value_0_is_free")}</FormNote>

              <InputLabel disabled={loading} error={errors.currency}>
                {t("subscription.currency")}
              </InputLabel>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder={t("subscription.choose_currency")}
                    value={field.value}
                    onChange={field.onChange}
                    options={mockCurrencies}
                    disabled={loading}
                    error={errors.currency}
                  />
                )}
              />

              <InputLabel
                error={errors.period}
                optionalText={t("subscription.in_days")}
                disabled={loading}
              >
                {t("subscription.period")}
              </InputLabel>
              <Controller
                control={control}
                name="period"
                render={({ field }) => (
                  <FormInput
                    value={field.value}
                    onChange={e => handleNumberChange(e, field.onChange)}
                    type="number"
                    error={errors.period}
                    disabled={loading}
                  />
                )}
              />

              <InputLabel error={errors.commission} optionalText="%" disabled={loading}>
                {t("subscription.commission_per_shop")}
              </InputLabel>
              <Controller
                control={control}
                name="commission"
                render={({ field }) => (
                  <FormInput
                    value={field.value}
                    onChange={e => handleNumberChange(e, field.onChange)}
                    type="number"
                    error={errors.commission}
                    disabled={loading}
                  />
                )}
              />
              <FormNote style={{ marginTop: 0 }}>{t("subscription.commission_hint")}</FormNote>
            </FormCard>
          </Grid>
        </Grid>

        <SaveButtons margin="40px 0 0" justifyContent="flex-end">
          <Button
            disabled={
              !isDirty || loading || !isValid || Boolean(errorStreets) || Boolean(errorCategories)
            }
            type="submit"
            width="180px"
          >
            {packageId ? t("ui.save_changes") : t("ui.create")}
          </Button>
        </SaveButtons>
      </Form>
    </StyledContainer>
  );
};

export default FormPackageInformation;
