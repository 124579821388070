import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import FormCard from "../../../../UI/Form/FormCard";
import { Form } from "../../../../UI/Form/MyForm";
import FormHeader from "../../../../UI/Form/FormHeader";
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import CheckboxContainer from "../../../../UI/Checkbox/CheckboxContainer";
import Select from "../../../../UI/Select";
import SelectAllAutocomplete from "../../../../package/src/SelectAllAutocomplete";
import Button from "../../../../UI/Button/Button";
import {
  allProductCategoriesQuery, queryPackageCategories,
  shopCategoriesQuery,
  shopQuery,
  shopWithProductBrandsQuery,
  shopWithProductCategoriesQuery,
} from "../../graphql/queries";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import {
  createFlatRateFulfillmentMethod,
  createShopMutation,
  enablePaymentMethodForShop,
  publishShop,
  updateShopMutation,
} from "../../graphql/mutations";
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {ShopSchema} from '../../schemas';
import SaveButtons from "../../../../UI/Button/SaveButtons";
import Dialog from "../../../../UI/Dialog/Dialog";
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../../UI/Button/Button";
import ShopCategoryForm from "../shop-category/ShopCategoryForm";
// import ECommerceHostingForm from "./ECommerceHostingForm";
import FormNote from "../../../../UI/Form/FormNote";
import FormInputPhoneNumber from "../../../../UI/Form/FormInputPhoneNumber";
import { allProductBrandsQuery } from "../../../../package/src/BrandSelectorWithData/graphql/queries";
import { useTranslation } from "react-i18next";
import { ShopEditContext } from "../../../../package/src/context/ShopEditContext";
import styled from "styled-components";
import shopBagsImg from '../../../../images/shop_bags.png';

const ShopBagsImg = styled.img`
  position: absolute;
  top: 9px;
  right: 9px;
`;

const ShopEditForm = ({ shopId, viewer }) => {
  const godmode = viewer?.type === "godmode";
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    subscriptionId,
    packageId,
    quiz: { eCommerce },
  } = useContext(ShopEditContext);

  const productSortingOptions = useMemo(() => [
    { name: t('shops.newest'), value: "newest" },
    { name: t('shops.oldest'), value: "oldest" },
    { name: t('shops.most_viewed'), value: "mostViewed" },
    { name: t('shops.most_purchased'), value: "mostPurchased" },
    { name: t('shops.most_expensive'), value: "mostExpensive" },
    { name: t('shops.least_expensive'), value: "leastExpensive" },
  ], [t]);

  const helpTexts = useMemo(() => {
    return {
      category: t('shops.help_text_category'),
      brands: t('shops.help_text_brands'),
      sortBy: t('shops.help_text_sortby'),
    }
  }, [t]);


  // form states
  const [loading, setLoading] = useState(true);
  const [savingChanges, setSavingChanges] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [phoneError, setPhoneError] = useState(null);

  // form values
  const [hostedIdOld, setHostedIdOld] = useState(false);
  const [shopCategoryIdOld, setShopCategoryIdOld] = useState('');

  // shopCategoryNameOld используется, чтобы отображать текущую категорию в случае, если она отсутствует в списке категорий
  const [shopCategoryNameOld, setShopCategoryNameOld] = useState('');

  // form options
  const [shopCatsList, setShopCatsList] = useState([]);
  const [productCatsList, setProductCatsList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const methods = useForm({
    resolver: yupResolver(ShopSchema),
    defaultValues: {
      shopName: '',
      shopCategoryId: '',
      productCategoryIds: [],
      brandIds: [],
      email: '',
      phoneNumber: '',
      whatsapp: '',
      sortBy: 'newest',
      facebook: '',
      instagram: '',
      twitter: '',
      isHostedForOBuy: false,
      isVisible: false,
      eCommerceLink: '',
    },
  });

  const { control, handleSubmit, formState: { errors, isDirty }, reset } = methods;

  const getShopData = () => {
    if (!shopId) {
      return Promise.resolve();
    }

    return apolloClient.query({
      query: shopQuery,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    })
  }

  const getShopDataProductCats = () => {
    if (!shopId) {
      return Promise.resolve();
    }

    return apolloClient.query({
      query: shopWithProductCategoriesQuery,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    })
  }

  const getShopDataBrandsCats = () => {
    if (!shopId) {
      return Promise.resolve();
    }

    return apolloClient.query({
      query: shopWithProductBrandsQuery,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    })
  }

  const getShopCategories = () => {
    return apolloClient.query({
      query: shopCategoriesQuery,
      variables: {
        id: [],
        first: 200,
      },
      fetchPolicy: "network-only",
    })
  }

  const getProductCategories = () => {
    return apolloClient.query({
      query: allProductCategoriesQuery,
      variables: {
        productCategoryIds: [],
        first: 200
      },
      fetchPolicy: "network-only",
    })
  }

  const getBrandList = () => {
    return apolloClient.query({
      query: allProductBrandsQuery,
      variables: {
        productBrandIds: [],
      },
      fetchPolicy: "network-only",
    })
  }

  const getPackageCategories = () => {
    return apolloClient.query({
      query: queryPackageCategories,
      variables: {
        id: packageId,
      },
      fetchPolicy: "network-only",
    })
  }

  const enablePaymentMethod = (id) => {
    return apolloClient.mutate({
      mutation: enablePaymentMethodForShop,
      variables: {
        input: {
          isEnabled: true,
          paymentMethodName: "stripe_card",
          shopId: id,
        },
      },
    })
  }

  const createFulfillmentMethod = (id) => {
    return apolloClient.mutate({
      mutation: createFlatRateFulfillmentMethod,
      variables: {
        input: {
          shopId: id,
          method: {
            cost: 0,
            handling: 0,
            rate: 0,
            fulfillmentTypes: 'shipping',
            group: "Free",
            isEnabled: true,
            label: "Label",
            name: "Fake delivery",
          },
        },
      },
    })
  }

  const mutateShop = (payload, isUpdate) => {
    if (isUpdate) {
      return apolloClient.mutate({
        mutation: updateShopMutation,
        variables: {
          input: payload,
        },
      });
    }

    return apolloClient.mutate({
      mutation: createShopMutation,
      variables: {
        input: payload,
      },
    })
  }

  useEffect(() => {
      setLoading(true);
      Promise.allSettled([
        getShopCategories(),
        getShopData(), // only if edit
        getShopDataProductCats(), // only if edit
        getShopDataBrandsCats(), // only if edit
        getProductCategories(),
        getBrandList(),
        getPackageCategories(), // for filter shop categories
      ])
        .then(res => {
          const categories = res[0]?.value?.data.getShopCategories?.nodes || []; // общий список категорий магазинов
          const shopData = res[1]?.value?.data.shop; // данные выбранного магазина, исключая категории продуктов и брендов
          const shopDataProductCats = res[2]?.value?.data.shop.productCategories || []; // Product Category, может быть много
          const shopDataBrandsCats = res[3]?.value?.data.shop.productBrands || []; // Brands, может быть много
          const productCats = res[4].value?.data.productCategories?.nodes || []; // общий список категорий продуктов
          const brandList = res[5].value?.data.productBrands?.nodes || []; // общий список брендов
          const packageCategories = res[6].value?.data.subscriptionPackage?.categoriesIds || []; // список категорий, которые разрешены в подписке

          setShopCatsList(categories
            .filter((c) => packageCategories.includes(c._id))
            .map(item => {
            return {
              name: item.name,
              value: item._id,
            }
          }));

          setProductCatsList(productCats.map(item => {
            return {
              label: item.name,
              value: item._id,
            }
          }));

          setBrandList(brandList.map(item => {
            return {
              label: item.name,
              value: item._id,
            }
          }));

          if (!shopData) {
            return;
          }

          const shopCategoryId = shopData.getShopCategory?._id || '';
          setShopCategoryIdOld(shopCategoryId);
          setShopCategoryNameOld(shopData.getShopCategory?.name || '')

          const hosted = shopData.eCommerceEnabled;
          setHostedIdOld(hosted);

          reset({
            shopName: shopData.name || '',
            shopCategoryId,
            productCategoryIds: shopDataProductCats.map(item => item._id),
            brandIds: shopDataBrandsCats.map(item => item._id),
            email: shopData?.emails
              ? shopData.emails.map(email => email.address).join(", ")
              : "",
            phoneNumber: shopData.phoneNumbers ? shopData.phoneNumbers.join(", ") : "",
            whatsapp: '', // todo
            sortBy: shopData.defaultCatalogSorting || 'newest',
            facebook: shopData.facebook || '',
            instagram: shopData.instagram || '',
            twitter: shopData.twitter || '',
            isHostedForOBuy: hosted,
            isVisible: shopData.active,
            eCommerceLink: shopData.linkToExternalWebsite || '',
          });
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setLoading(false))
  }, [])

  const onSubmit = useCallback((formData) => {
    setSavingChanges(true);

    const phoneNumbers = formData.phoneNumber ? [formData.phoneNumber] : [];
    const emails = [{ address: formData.email }];

    const payloadCreate = {
      name: formData.shopName,
      defaultCatalogSorting: formData.sortBy,
      type: 'merchant',
      companyId: viewer?.companyId,
      emails,
      phoneNumbers,
      subscriptionId,
      shopCategoryId: formData.shopCategoryId,
      eCommerceEnabled: formData.isHostedForOBuy,
    }

    const payloadUpdate = {
      shopId,
      name: formData.shopName,
      active: formData.isVisible,
      emails,
      phoneNumbers,
      facebook: formData.facebook || '',
      instagram: formData.instagram || '',
      twitter: formData.twitter || '',
      linkToExternalWebsite: formData.eCommerceLink || '',
      defaultCatalogSorting: formData.sortBy,
      productBrandIds: formData.brandIds,
      productCategoryIds: formData.productCategoryIds,
      eCommerceEnabled: formData.isHostedForOBuy,
      // Only godmode can update the following fields without placing
      // separate Merchant Request
      ...(godmode
        ? {
          shopCategoryId: formData.shopCategoryId,
        }
        : {}),
    };

    mutateShop(shopId ? payloadUpdate : payloadCreate, shopId)
      .then(res => {
        if (shopId) {
          // after shop updating
          reset(formData);
          setSavingChanges(false);
          enqueueSnackbar(t('snackbar.saved_changes_success'), {variant: 'success'});
          return;
        }

        // after shop creating
        const newShopId = res.data.createShop.shop._id;
        payloadUpdate.shopId = newShopId;

        enablePaymentMethod(newShopId)
          .then(() => {
            return createFulfillmentMethod(newShopId);
          })
          .then(() => {
            return mutateShop(payloadUpdate, true);
          })
          .then(() => {
            enqueueSnackbar(t('shops.created_shop_success'), {variant: 'success'});
            history.push(`/shops/edit/${newShopId}`);
          })
      })
      .catch((e) => {
        if (!shopId && e.message?.indexOf('duplicate key error') > -1) {
          enqueueSnackbar(t('shops.shop_already_exists', { shopName: formData.shopName }), {variant: 'error'});
        } else {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        }
        setSavingChanges(false);
        console.log(e.message);
      })
  }, [shopId, viewer, godmode, subscriptionId])

  const handleAutocompleteChange = (e, func) => {
    const result = e.map(item => item.value);
    func(result);
  }

  return (
    <FormProvider
      {...methods}
      loading={loading}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        width='100%'
      >
        <Grid container spacing={2}>

          <Grid item xs={12} lg={7}>
            <FormCard>
              <FormHeader>{t('shops.details')}</FormHeader>
              <InputLabel disabled={loading} error={errors.shopName}>{t('shops.shop_name')}</InputLabel>
              <Controller
                name="shopName"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('shops.write_title')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.shopName}
                />}
              />

              {
                (!shopId || godmode)
                && <>
                  <InputLabel disabled={loading} error={errors.shopCategoryId}>{t('shops.shop_category')}</InputLabel>
                  <Controller
                    name="shopCategoryId"
                    control={control}
                    render={({ field }) => {
                      const isValidValue = shopCatsList?.some((k) => k.value === field.value);
                      return <Select
                        placeholder={isValidValue ? t('shops.select_shop_category') : shopCategoryNameOld}
                        value={isValidValue ? field.value : ''}
                        onChange={field.onChange}
                        options={shopCatsList}
                        disabled={loading}
                        error={errors.shopCategoryId}
                      />;
                    }}
                  />
                  <FormNote
                    style={{ marginTop: '6px'}}
                  >
                    {t('shops.shop_appear_in_streets')}
                  </FormNote>
                </>
              }

              <InputLabel helpText={helpTexts.category} disabled={loading}>{t('shops.product_categories')}</InputLabel>
              <Controller
                name="productCategoryIds"
                control={control}
                render={({ field }) => <SelectAllAutocomplete
                  placeholder={t('shops.select_product_categories')}
                  selectAllLabel="All categories"
                  onChange={(e) => handleAutocompleteChange(e, field.onChange)}
                  items={productCatsList.map(item => ({
                    ...item,
                    selected: field.value.includes(item.value),
                  }))}
                  isMultiSelect
                  disabled={loading}
                />}
              />
              {
                eCommerce &&
                <>
                  <InputLabel helpText={helpTexts.brands} disabled={loading}>{t('shops.brands')}</InputLabel>
                  <Controller
                    name="brandIds"
                    control={control}
                    render={({ field }) => <SelectAllAutocomplete
                      placeholder={t('shops.choose_brands')}
                      selectAllLabel={t('shops.all_brands')}
                      onChange={(e) => handleAutocompleteChange(e, field.onChange)}
                      items={brandList.map(item => ({
                        ...item,
                        selected: field.value.includes(item.value),
                      }))}
                      isMultiSelect
                      disabled={loading}
                    />}
                  />
                </>
              }
              <InputLabel error={errors.email} disabled={loading}>{t('shops.email')}</InputLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('shops.write_email')}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.email}
                  disabled={loading}
                />}
              />
              <InputLabel optionalText={t('ui.optional')} disabled={loading}>{t('shops.phone_number')}</InputLabel>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => <FormInputPhoneNumber
                  placeholder={t('shops.write_phone_number')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  phoneError={phoneError}
                  setPhoneError={setPhoneError}
                />}
              />
              <InputLabel optionalText={t('ui.optional')} disabled={loading}>{t('shops.whatsapp_business_number')}</InputLabel>
              <Controller
                name="whatsapp"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('shops.write_whatsapp')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                />}
              />
            </FormCard>

            {
              (!godmode && shopId) ?
              <>
                <ShopCategoryForm
                  shopCategoryIdOld={shopCategoryIdOld}
                  shopCategoryNameOld={shopCategoryNameOld}
                  shopId={shopId}
                  shopCatsList={shopCatsList}
                />

                {/*<ECommerceHostingForm*/}
                {/*  hostedIdOld={hostedIdOld}*/}
                {/*  shopId={shopId}*/}
                {/*/>*/}
              </> : null
            }
          </Grid>

          <Grid item xs={12} lg={5}>
            <FormCard style={{ padding: '23px 32px 16px', position: 'relative', height: '88px', overflow: 'hidden' }}>
              <CheckboxContainer style={{ position: 'relative', zIndex: 1 }}>
                <Controller
                  name="isVisible"
                  control={control}
                  render={({ field }) => <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                  />}
                />
                {t('shops.appears_for_shoppers')}
              </CheckboxContainer>
              <ShopBagsImg src={shopBagsImg} alt=''/>
            </FormCard>

            <FormCard>
              <FormHeader>{t('shops.sort_products_by')}</FormHeader>
              <InputLabel helpText={helpTexts.sortBy} disabled={loading}>{t('shops.sort_by')}</InputLabel>
              <Controller
                name="sortBy"
                control={control}
                render={({ field }) => <Select
                  placeholder={t('ui.select')}
                  value={field.value}
                  onChange={field.onChange}
                  options={productSortingOptions}
                  disabled={loading}
                />}
              />
            </FormCard>

            <FormCard>
              <FormHeader>{t('shops.social_networks')}</FormHeader>
              <InputLabel error={errors.facebook} optionalText={t('ui.optional')} disabled={loading}>Facebook</InputLabel>
              <Controller
                name="facebook"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('shops.write_facebook')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.facebook}
                />}
              />
              <InputLabel error={errors.instagram} optionalText={t('ui.optional')} disabled={loading}>Instagram</InputLabel>
              <Controller
                name="instagram"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('shops.write_instagram')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.instagram}
                />}
              />
              <InputLabel error={errors.twitter} optionalText={t('ui.optional')} disabled={loading}>Twitter</InputLabel>
              <Controller
                name="twitter"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('shops.write_twitter')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.twitter}
                />}
              />
            </FormCard>

            <FormCard>
              <FormHeader>{t('shops.conditions')}</FormHeader>
              <CheckboxContainer>
                <Controller
                  name="isHostedForOBuy"
                  control={control}
                  render={({ field }) => <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    disabled={loading}
                  />}
                />
                {t('shops.activate_wodersouq_ecommerce')}
              </CheckboxContainer>

              <InputLabel error={errors.eCommerceLink} disabled={loading}>
                {t('shops.link_to_ecommerce')}
              </InputLabel>
              <Controller
                name="eCommerceLink"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('shops.write_link_here')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.eCommerceLink}
                />}
              />
            </FormCard>
          </Grid>

          <SaveButtons margin='24px 0'>
            <Button
              disabled={!isDirty || savingChanges || loading || Boolean(phoneError)}
              type='submit'
              width='180px'
            >
              {shopId ? t('ui.save_changes') : t('ui.create')}
            </Button>
            <Button
              disabled={savingChanges || loading || !shopId}
              width='180px'
              handleClick={() => setDialogOpen(true)}
            >
              {t('ui.publish')}
            </Button>
          </SaveButtons>

        </Grid>

        <Dialog
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
        >
          <DialogMessages
            title={t('shops.publish_q')}
          />
          <DialogButtons justifyButtons='center'>
            <StyledButton
              width='180px'
              handleClick={() => {
                setDialogOpen(false);
                setSavingChanges(true);

                apolloClient.mutate({
                  mutation: publishShop,
                  variables: {
                    shopId,
                  },
                })
                  .then(res => {
                    if (res) {
                      enqueueSnackbar(t('shops.publish_success'), {variant: 'success'});
                    }
                  })
                  .catch((e) => {
                    enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
                    console.log(e.message);
                  })
                  .finally(() => setSavingChanges(false))
              }}
            >
              {t('ui.yes')}
            </StyledButton>
            <StyledButton
              width='180px'
              mytype='secondary'
              handleClick={() => setDialogOpen(false)}
            >
              {t('ui.no')}
            </StyledButton>
          </DialogButtons>
        </Dialog>
      </Form>
    </FormProvider>
  )
}

export default ShopEditForm;
