import React, { useContext, useEffect, useState } from "react";
import LayoutContent from "../layouts/LayoutContent";
import LayoutForm from "../layouts/LayoutForm";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { SignupSchema } from "../schemas";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import CustomButton from "../../../../UI/Button";
import HorizontalRuleWithText from "../components/HorizontalRuleWithText";
import LoginGoogle from "../components/LoginGoogle";
import { FormHeaderText, FormHeaderWrap } from "../styles";
import LinkButton from "../components/LinkButton";
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import { Grid } from "@material-ui/core";
import CustomDatePicker from "../../DatePicker";
import CustomCheckbox from "../../../../UI/Checkbox";
import styled from "styled-components";
import RegError from "../components/RegError";
import CheckboxContainer from "../../../../UI/Checkbox/CheckboxContainer";
import InputErrorMessage from "../../../../UI/Form/InputErrorMessage";
import PhoneInputWithFlag from "../../../../UI/PhoneInputWithFlag/PhoneInputWithFlag";
import { Form } from "../../../../UI/Form/MyForm";
import { Auth } from "aws-amplify";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const TermsError = styled("div")`
  p {
    margin: 0;
  }
`;

const PageSignup = () => {
  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isTermsError, setIsTermsError] = useState(false);
  const [mfa, setMfa] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const { t } = useTranslation("reg");

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      dateOfBirth: new Date(),
      phoneNumber: "",
    },
  });

  const { onSetUser, onSetPage, onSetTypedEmail } = useContext(AuthContext);

  const watchCognitoFields = useWatch({ control, name: ["password", "email", "phoneNumber"] });

  useEffect(() => {
    setCognitoError(null);
  }, [watchCognitoFields]);

  const onSubmit = async formData => {
    if (!termsAccepted) {
      setIsTermsError(true);
      return;
    }

    setLoading(true);

    Auth.signUp({
      username: formData.email,
      password: formData.password,
      attributes: {
        given_name: formData.firstName,
        family_name: formData.lastName,
        phone_number: formData.phoneNumber,
        email: formData.email,
        birthdate: moment(formData.dateOfBirth).format("YYYY-MM-DD"),
      },
    })
      .then(data => {
        console.log(data);
        if (data?.user) {
          // mfa can only be set for a verified user, so we save the checkbox value in local storage
          let newValue = {
            [formData.email]: mfa,
          };
          const saved = localStorage.getItem("isMFA");
          if (saved) {
            const parsed = JSON.parse(saved);
            newValue = {
              ...parsed,
              [formData.email]: mfa,
            };
          }
          localStorage.setItem("isMFA", JSON.stringify(newValue));

          onSetTypedEmail(formData.email);
          onSetUser(data.user);
          onSetPage("verifyCode");
        } else {
          throw new Error("User not created");
        }
      })
      .catch(e => {
        console.log(e);
        setCognitoError(e.message);
        setLoading(false);
      });
  };

  const handleLogin = () => {
    onSetPage("login");
  };

  const handleBack = () => {
    onSetPage("login");
  };

  const handleToggleMfa = () => {
    setMfa(prev => !prev);
  };

  const handleToggleTerms = () => {
    setTermsAccepted(prev => !prev);
    setIsTermsError(false);
  };

  return (
    <LayoutContent>
      <LayoutForm>
        <FormHeaderWrap>
          <FormHeaderText>
            {t("signup")} {t("merchant")}
          </FormHeaderText>
          <LinkButton disabled={loading} onClick={handleLogin}>
            {t("login")}
          </LinkButton>
        </FormHeaderWrap>

        <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel>{t("first_name")}</InputLabel>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    placeholder={t("write_your_first_name")}
                    error={errors.firstName}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{t("last_name")}</InputLabel>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    placeholder={t("write_your_surname")}
                    error={errors.lastName}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel>{t("email")}</InputLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    placeholder={t("write_your_email")}
                    error={errors.email}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{t("confirm_email")}</InputLabel>
              <Controller
                name="confirmEmail"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    error={errors.confirmEmail}
                    placeholder={t("confirm_your_email")}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel>{t("password")}</InputLabel>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="password"
                    placeholder={t("write_password")}
                    error={errors.password}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{t("confirm_password")}</InputLabel>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="password"
                    placeholder={t("confirm_password")}
                    error={errors.confirmPassword}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel> {t("date_of_birth")}</InputLabel>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <CustomDatePicker
                    value={field.value}
                    onChange={newDate => field.onChange(newDate)}
                    error={errors.dateOfBirth}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{t("phone_number")}</InputLabel>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <PhoneInputWithFlag
                    field={field}
                    placeholder={t("write_your_phone_number")}
                    error={errors.phoneNumber}
                    phoneError={phoneError}
                    setPhoneError={setPhoneError}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
          </Grid>

          <CheckboxContainer margin="24px 0 0 0">
            <CustomCheckbox checked={mfa} onChange={handleToggleMfa} />
            {t("enable_two_factor")}
          </CheckboxContainer>

          <CheckboxContainer margin="8px 0 0 0">
            <CustomCheckbox checked={termsAccepted} onChange={handleToggleTerms} />
            {t("accept_terms")}
          </CheckboxContainer>

          {!termsAccepted && isTermsError ? (
            <TermsError>
              <InputErrorMessage> {t("errors.please_accept_terms")}</InputErrorMessage>
            </TermsError>
          ) : null}

          <HorizontalRuleWithText innerText={t("or")} />

          <LoginGoogle text={t("google_signin")} loading={loading} />

          {cognitoError ? <RegError text={cognitoError} /> : null}

          <DialogButtons justifyButtons="space-between">
            <CustomButton
              width="170px"
              mytype="secondary"
              disabled={loading}
              handleClick={handleBack}
            >
              {t("ui.back")}
            </CustomButton>
            <CustomButton
              width="170px"
              type="submit"
              disabled={!isDirty || loading || Boolean(cognitoError) || Boolean(phoneError)}
            >
              {t("signup")}
            </CustomButton>
          </DialogButtons>
        </Form>
      </LayoutForm>
    </LayoutContent>
  );
};

export default PageSignup;
