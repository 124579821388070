import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid } from "@material-ui/core";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getAllStreets } from "../graphql/queries";
import DataTable from "../../../UI/DataTable/DataTable";
import { useSpacesColumns } from "../hooks/useSpacesColumns";
import { useTranslation } from "react-i18next";
import useModifiers from "../hooks/useModifiers";

const StreetPage = () => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { streetModifyData } = useModifiers();

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const { columnsStreet } = useSpacesColumns();

  const onFetchData = useCallback(() => {
    setIsLoading(true);

    apolloClient
      .query({
        query: getAllStreets,
        fetchPolicy: "network-only",
      })
      .then(res => {
        setTableData(res.data.availableStreets.data);
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      })
      .finally(() => setIsLoading(false));
  }, [t]);

  const dataProps = useMemo(() => {
    return streetModifyData(tableData);
  }, [tableData, streetModifyData]);

  return (
    <>
      <Toolbar title={t("menu.streets")} />
      <Grid container spacing={2}>
        <DataTable
          columns={columnsStreet}
          isLoading={isLoading}
          data={dataProps}
          handleFetchData={onFetchData}
          defaultSortField="createdAt"
          defaultSortOrder="desc"
          showCheckboxes={false}
          maxHeight="calc(100vh - 280px)"
          maxHeightLaptop="calc(100vh - 280px)"
          maxHeightMobile="calc(100vh - 280px)"
          marginTopMobile="16px"
        />
      </Grid>
    </>
  );
};

export default StreetPage;
