import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Table, TableBody, TableCell, TableRow, Box, Radio } from "@material-ui/core";
import EnhancedTableHead from "./TableHead";
import TablePagination from "./TablePagination";
import Checkbox from "../Checkbox";
import RowOptions from "./RowOptions";
import { colors } from "../../constants";
import IconButtons from "../IconButtons";
import { LanguageContext } from "../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";
import HideValueComponent from "./HideValueComponent";
import FormControlLabel from "../FormControlLabel";

const Wrapper = styled(Box)`
  width: 100%;
  max-width: ${props => (props.maxwidth ? props.maxwidth : "calc(100vw - 490px)")};
  max-height: ${props => (props.maxheight ? props.maxheight : "unset")};
  margin-top: 16px;
  overflow: auto;
  border-bottom: 1px solid #e9e9e9;

  // prevent shadow cut

  padding: 0 20px 20px 20px;
  margin-left: -20px;

  // scroll style

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.blue};
    border-radius: 8px;
  }

  @media all and (max-width: 1279px) {
    max-height: ${props => (props.maxheightlaptop ? props.maxheightlaptop : "unset")};
  }

  @media all and (max-width: 959px) {
    max-height: ${props => (props.maxheightmobile ? props.maxheightmobile : "unset")};
    max-width: calc(100vw - 90px);
    margin-left: -10px;
    margin-top: ${props => (props.margintopmobile ? props.margintopmobile : 0)};
  }

  ${props =>
    props.isrtl === "true" &&
    css`
      margin-left: 0;
      margin-right: -20px;

      @media all and (max-width: 959px) {
        margin-left: 0;
        margin-right: -10px;
      }
    `}
`;

const StyledTable = styled(Table)`
  &.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 16px;
  }

  @media all and (max-width: 959px) {
    &.MuiTable-root {
      border-spacing: 0;
    }
  }
`;

const StyledTableBody = styled(TableBody)`
  position: relative;

  .loading {
    position: absolute;
    top: 50%;
    left: 45%;
    font-size: 2em;
  }
`;

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    position: relative;

    &.MuiTableRow-hover {
      background-color: transparent !important;
    }

    & .boxShadow {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
      background: transparent;
      position: absolute;
      left: 0;
      z-index: 0;
    }

    ${props =>
      props.showcheckboxes === "false" &&
      css`
        .MuiTableCell-root:nth-child(1) {
          padding-left: ${props.isrtl === "true" ? "8px" : "16px"};
          padding-right: ${props.isrtl === "true" ? "16px" : "8px"};
        }
      `}
  }
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 12px 8px;
    z-index: 1;
    position: relative;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.black};
    text-align: left;
    word-break: break-all;
    min-width: ${props => props.minwidth};
    max-width: 300px;
    overflow: hidden;        
    white-space: nowrap;        
    text-overflow: ellipsis;  

  }

  ${props =>
    props.isrtl === "true" &&
    css`
      &.MuiTableCell-root {
        text-align: right;
      }
    `}
`;

function EnhancedTable({
  columns,
  data,
  isLoading,
  setSelectedRows,
  selectedRows = [],
  handleFetchData,
  pageCount,
  totalCount,
  searchValue,
  reloadTable,
  showCheckboxes = true,
  singleSelect = false,
  defaultSortField,
  defaultSortOrder,
  filtersByKey,
  maxHeight,
  maxHeightLaptop,
  maxHeightMobile,
  marginTopMobile,
  maxWidth,
}) {
  const { t } = useTranslation();
  const { isRtl } = useContext(LanguageContext);
  const [order, setOrder] = useState(defaultSortOrder ? defaultSortOrder : "asc");
  const [orderBy, setOrderBy] = useState(defaultSortField ? defaultSortField : "id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const previousSearch = useRef();
  const previousRowsPerPage = useRef();
  const previousFilters = useRef();

  useEffect(() => {
    const saveRefs = () => {
      previousSearch.current = searchValue;
      previousRowsPerPage.current = rowsPerPage;
      previousFilters.current = filtersByKey;
    };

    if (
      page !== 0 &&
      (previousSearch.current !== searchValue ||
        previousRowsPerPage.current !== rowsPerPage ||
        previousFilters.current !== filtersByKey)
    ) {
      // the page will change, so there's no sense to fetch 2 times
      setPage(0);
      saveRefs();
      return;
    }

    setSelectedRows && setSelectedRows([]);
    handleFetchData &&
      handleFetchData({
        globalFilter: searchValue,
        pageIndex: page,
        pageSize: rowsPerPage,
        sortOrder: order,
        sortBy: orderBy,
        filtersByKey,
      });

    saveRefs();
  }, [rowsPerPage, page, order, orderBy, searchValue, filtersByKey, reloadTable]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.id);
      setSelectedRows && setSelectedRows(newSelecteds);
      return;
    }
    setSelectedRows && setSelectedRows([]);
  };

  const handleClick = (event, name) => {
    if (!selectedRows || !setSelectedRows) {
      return;
    }

    if (singleSelect) {
      setSelectedRows([name]);
      return;
    }

    const selectedIndex = selectedRows.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelected);
  };

  const handleNextPage = () => {
    setPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    setPage(prev => prev - 1);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => (selectedRows ? selectedRows.indexOf(name) !== -1 : false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = () => {
    if (isLoading) {
      return rowsPerPage;
    }
    if (page > 0) {
      const total = totalCount || 0;
      return Math.max(0, (1 + page) * rowsPerPage - total);
    }
    return 0;
  };

  const renderCell = useCallback((row, col) => {
    if (col.name === "options") {
      if (Array.isArray(row.options)) {
        return (
          <RowOptions
            optionsComponents={row.modifyOptions ? row.modifyOptions(row.options) : row.options}
            rowId={row.id}
          />
        );
      } else {
        return (
          <IconButtons
            type={row.options.buttonType}
            handleClick={() => row.options.handleOptionClick(row.id)}
          />
        );
      }
    }
    let component = row[col.name];
    if (col.hideValues) {
      component = <HideValueComponent value={component} />;
    }
    if (col.isBold) {
      component = <b>{component}</b>;
    }
    return component;
  }, []);

  return (
    <>
      <Wrapper
        maxheight={maxHeight}
        maxheightlaptop={maxHeightLaptop}
        maxheightmobile={maxHeightMobile}
        margintopmobile={marginTopMobile}
        maxwidth={maxWidth}
        isrtl={String(isRtl)}
      >
        <StyledTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle" stickyHeader>
          <EnhancedTableHead
            numSelected={selectedRows?.length || 0}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            columns={columns}
            showCheckboxes={showCheckboxes}
            singleSelect={singleSelect}
          />
          <StyledTableBody>
            {isLoading ? (
              <tr className="loading">
                <td>{t("ui.loading")}...</td>
              </tr>
            ) : (
              data.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    showcheckboxes={String(showCheckboxes || singleSelect)}
                    isrtl={String(isRtl)}
                  >
                    {showCheckboxes ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onChange={event => handleClick(event, row.id)}
                        />
                      </TableCell>
                    ) : (
                      singleSelect && (
                        <TableCell>
                          <FormControlLabel
                            checked={row.id === selectedRows[0]}
                            control={<Radio />}
                            onClick={event => handleClick(event, row.id)}
                            margin="0"
                            padding="4px"
                          />
                        </TableCell>
                      )
                    )}
                    {columns.map(col => (
                      <StyledTableCell
                        key={col.name}
                        isrtl={String(isRtl)}
                        minwidth={col.name === "options" ? "unset" : "100px"}
                      >
                        {renderCell(row, col)}
                      </StyledTableCell>
                    ))}
                    <td className="boxShadow" />
                  </StyledTableRow>
                );
              })
            )}
            {emptyRows > 0 &&
              [...Array(emptyRows > 10 ? 10 : emptyRows)].map((_, index) => (
                <StyledTableRow key={index}>
                  <TableCell />
                </StyledTableRow>
              ))}
          </StyledTableBody>
        </StyledTable>
      </Wrapper>
      {pageCount ? (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          pageCount={pageCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageNext={handleNextPage}
          onPagePrevious={handlePreviousPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
}

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      isBold: PropTypes.bool,
      postfix: PropTypes.string,
      sortable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
      modifyOptions: PropTypes.func,
      // 'column Id': cell content
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  handleFetchData: PropTypes.func,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  searchValue: PropTypes.string,
  reloadTable: PropTypes.bool,
  showCheckboxes: PropTypes.bool,
  defaultSortOrder: PropTypes.string,
  defaultSortField: PropTypes.string,
  maxHeight: PropTypes.string,
  maxHeightLaptop: PropTypes.string,
  maxHeightMobile: PropTypes.string,
};

export default EnhancedTable;
