import gql from "graphql-tag";
export const getAllPasses = gql`
  query passes($input: PassesQueryInput) {
    passes(input: $input) {
      data {
        id
        name
        duration
        cost
        isActive
        thumbnailId
        thumbnailUrl
        badgeId
        badgeUrl
        createdAt
        updatedAt
      }
      paging {
        total
        limit
        offset
        pages
        page
      }
    }
  }
`;
