export const Pass = `
  id
  name
  duration
  cost
  isActive
  thumbnailId
  thumbnailUrl
  badgeId
  badgeUrl
  createdAt
  updatedAt
`;
