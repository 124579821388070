import React from "react";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";

const DialogButtonsContainer = ({ confirmAction, declineAction }) => {
  const { t } = useTranslation();

  return (
    <DialogButtons justifyButtons="center">
      <StyledButton width="180px" handleClick={confirmAction}>
        {t("ui.yes")}
      </StyledButton>
      <StyledButton width="180px" mytype="secondary" handleClick={declineAction}>
        {t("ui.no")}
      </StyledButton>
    </DialogButtons>
  );
};

export const RemoveMedia = props => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages title={t("product.remove_media")} message={t("product.remove_media_q")} />
      <DialogButtonsContainer {...props} />
    </>
  );
};

export const DialogDeleteSingle = props => {
  const { t } = useTranslation();
  return (
    <>
      <DialogMessages
        title={t("product.delete_product_q")}
        message={t("product.delete_product_message")}
      />
      <DialogButtonsContainer {...props} />
    </>
  );
};
