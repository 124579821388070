import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import StyledButton from "../../../UI/Button";
import DataTable from "../../../UI/DataTable";
import svgEdit from "../../../icons/table-edit.svg";
import svgDelete from "../../../icons/delete.svg";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import { getAllPasses } from "../graphql/queries";
import { useTranslation } from "react-i18next";
import { usePassesColumns } from "../hooks/usePassesColumns";
import { constants } from "../../../constants";
import useModifiers from "../hooks/useModifiers";
import { removePass } from "../graphql/mutations";
import Dialog from "../../../UI/Dialog/Dialog";
import { DialogDeleteSingle } from "../components/Dialogs";
import Search from "../../../UI/Search";

const useStyles = makeStyles(theme => ({
  columnLeft: {
    display: "flex",
    gap: 20,
  },
  flexEnd: {
    display: "flex",
    gap: 20,
    justifyContent: "flex-end",
  },
  flexStart: {
    display: "flex",
    gap: 20,
  },
}));

const PassesPage = ({ history }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowId, setRowId] = useState(null);

  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = usePassesColumns();
  const { modifyData } = useModifiers();

  const rowOptions = useMemo(
    () => [
      {
        value: constants.OPTIONS.editPass,
        label: t("passes.edit_pass"),
        icon: svgEdit,
        handleOptionClick: rowId => {
          history.push(`/passes/edit/${rowId}`);
        },
      },
      {
        value: constants.OPTIONS.deletePass,
        label: t("passes.delete_pass"),
        icon: svgDelete,
        handleOptionClick: rowId => {
          setRowId(rowId);
          setDialogOpen(true);
        },
      },
    ],
    [t]
  );

  const removeOnePass = id => {
    apolloClient
      .mutate({
        mutation: removePass,
        variables: {
          input: {
            id,
          },
        },
      })
      .then(() => {
        setTableData(prevData => prevData.filter(item => item.id !== id));
        setDialogOpen(false);
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      });
  };

  const dataProps = useMemo(() => {
    return modifyData(tableData, "", rowOptions);
  }, [tableData, modifyData]);

  const onFetchData = useCallback(
    ({ pageSize }) => {
      setIsLoading(true);
      apolloClient
        .query({
          query: getAllPasses,
          fetchPolicy: "network-only",
        })
        .then(res => {
          const filteredData = res.data.passes.data.filter(pass =>
            pass.name.toLowerCase().includes(searchValue.toLowerCase())
          );
          const totalCount = res.data.passes.data?.length;
          setTotalCount(totalCount);
          setPageCount(Math.ceil(totalCount / pageSize));
          setTableData(filteredData);
        })
        .catch(e => {
          enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
          console.log(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [t, removeOnePass, searchValue]
  );

  const refetch = () => {
    setReloadTable(prev => !prev);
  };

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient
      .query({
        query: getAllPasses,
        fetchPolicy: "network-only",
      })
      .then(res => {
        const result = modifyData(res.data.passes.data, "export", null);
        const fields = columns.filter(item => item.name !== "options");
        downloadCSV(fields, result);
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      })
      .finally(() => setExporting(false));
  }, [t, modifyData]);

  const handleSearch = value => {
    setSearchValue(value.trim());
  };

  const createPassHandler = () => {
    history.push("/passes/create");
  };

  const renderRightColumn = myClass => {
    return (
      <Grid item xs={12} lg={5} className={classes[myClass]}>
        <StyledButton minwidth="250px" width="250px" handleClick={createPassHandler}>
          {t("passes.add_pass")}
        </StyledButton>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Toolbar title={t("spaces.passes")} />
        <Grid item xs={12} lg={7} className={classes.columnLeft}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t("ui.search")}
            useDebounce
          />

          <StyledButton
            width="140px"
            mytype="secondary"
            handleClick={exportData}
            disabled={exporting || !tableData.length}
          >
            {t("ui.export")}
          </StyledButton>
        </Grid>

        <Hidden lgUp>{renderRightColumn("flexStart")}</Hidden>

        <Hidden mdDown>{renderRightColumn("flexEnd")}</Hidden>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          showCheckboxes={false}
          maxHeight="calc(100vh - 280px)"
          maxHeightLaptop="calc(100vh - 380px)"
          maxHeightMobile="calc(100vh - 360px)"
        />

        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <DialogDeleteSingle
            confirmAction={() => removeOnePass(rowId)}
            declineAction={() => setDialogOpen(false)}
          />
        </Dialog>
      </Grid>
    </>
  );
};

export default PassesPage;
