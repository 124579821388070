import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSpacesColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        name: "id",
        label: "ID",
        sortable: false,
      },
      {
        name: "name",
        label: t("spaces.name"),
        sortable: false,
      },
      {
        name: "code",
        label: t("spaces.code"),
        sortable: false,
      },
      {
        name: "type",
        label: t("spaces.type"),
        sortable: false,
      },
      {
        name: "zone",
        label: t("spaces.zone"),
        sortable: false,
      },
      {
        name: "zoneId",
        label: t("spaces.zone_id"),
        sortable: false,
      },
      {
        name: "createdAt",
        label: t("spaces.registered_on"),
        sortable: false,
      },
    ],
    [t]
  );

  const columnsZones = useMemo(
    () => [
      {
        name: "id",
        label: "ID",
      },
      {
        name: "name",
        label: t("zones.name"),
      },
      {
        name: "defaultSpaceId",
        label: t("zones.default_space_id"),
      },
      {
        name: "defaultSpace",
        label: t("zones.default_space"),
      },
    ],
    [t]
  );

  const columnsStreet = useMemo(
    () => [
      {
        name: "id",
        label: "ID",
      },
      {
        name: "name",
        label: t("streets.name"),
      },
      {
        name: "zone",
        label: t("streets.zone"),
      },
      {
        name: "zoneId",
        label: t("streets.zone_id"),
      },
      {
        name: "type",
        label: t("streets.type"),
      },
      {
        name: "slug",
        label: t("streets.slug"),
      },
    ],
    [t]
  );

  return {
    columns,
    columnsZones,
    columnsStreet,
  };
};
