import { Container, styled } from "@material-ui/core";
import { Form } from "../../../UI/Form/MyForm";
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import { useForm } from "react-hook-form";
import InputLabel from "../../../UI/Form/InputLabel";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import FormNote from "../../../UI/Form/FormNote";
import { useCallback, useEffect, useRef, useState } from "react";
import { useApolloClient } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import {
  removeSpaceRestrictions,
  restrictSpace,
} from "../../spaces/graphql/restricted-space/mutations";
import { getAllSpaces } from "../../spaces/graphql/queries";

const StyledContainer = styled(Container)`
  width: 100%;
`;

const PassRestrictedSpaces = ({ isCanAddSpace }) => {
  const apolloClient = useApolloClient();
  const [spacesItem, setSpacesItem] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const previousSpaces = useRef([]);
  const isBatch = useRef(false);

  const { passId } = useParams();

  useEffect(() => {
    apolloClient
      .query({
        query: getAllSpaces,
        variables: {
          input: {
            paging: {
              page: 1,
              limit: 1000,
            },
          },
        },
        fetchPolicy: "network-only",
      })
      .then(res => {
        const spaces = res.data.spaces.data;

        const updateSpaces = spaces.map(s => ({
          value: s.id,
          label: s.name,
          selected: spaces.includes(s.id),
        }));

        setSpacesItem(updateSpaces);
        const restrictedToSpace = spaces.filter(space => space.restrictedToIds.includes(passId));

        if (restrictedToSpace) {
          const filteredRestricted = restrictedToSpace.filter(el => el !== null).map(el => el?.id);
          previousSpaces.current = filteredRestricted;
          setSpaces(filteredRestricted);
        }
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      });
  }, [setSpacesItem]);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const handleChangeSpace = useCallback(
    async selectedItems => {
      const newSpacesIds = selectedItems.map(item => item.value);
      const addedSpaces = newSpacesIds.filter(id => !previousSpaces.current.includes(id));
      const removedSpaces = previousSpaces.current.filter(id => !newSpacesIds.includes(id));

      try {
        if (addedSpaces.length > 1) {
          isBatch.current = true;
        }

        for (const spaceId of addedSpaces) {
          await apolloClient.mutate({
            mutation: restrictSpace,
            variables: {
              input: {
                spaceId,
                passId,
              },
            },
          });
          if (!isBatch.current) {
            enqueueSnackbar(t("spaces.restrict_success"), { variant: "success" });
          }
        }

        if (isBatch.current) {
          enqueueSnackbar(t("spaces.restrict_success_multiple"), { variant: "success" });
          isBatch.current = false;
        }

        for (const spaceId of removedSpaces) {
          await apolloClient.mutate({
            mutation: removeSpaceRestrictions,
            variables: {
              input: {
                spaceId,
                passId,
              },
            },
          });
          enqueueSnackbar(t("snackbar.update_success"), { variant: "success" });
        }
        previousSpaces.current = newSpacesIds;
      } catch (e) {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.error(e.message);
      }
    },
    [apolloClient, enqueueSnackbar, t, passId]
  );

  return (
    <FormCard>
      <Form width="100%">
        <FormHeader>{t("menu.spaces")}</FormHeader>

        <InputLabel>{t("menu.spaces")}</InputLabel>
        <SelectAllAutocomplete
          items={spacesItem}
          placeholder={
            isCanAddSpace || passId ? t("spaces.choose_space") : t("spaces.select_after_add")
          }
          selectAllLabel={t("spaces.all_spaces")}
          value={spaces}
          onChange={handleChangeSpace}
          disabled={!isCanAddSpace && !passId}
        />
        <FormNote>
          {isCanAddSpace || passId ? t("spaces.choose_spaces_hint") : t("spaces.select_after_add")}
        </FormNote>
      </Form>
    </FormCard>
  );
};
export default PassRestrictedSpaces;
