import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const usePassesColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        name: "id",
        label: "ID",
        sortable: false,
      },
      {
        name: "name",
        label: t("passes.name"),
        sortable: false,
      },
      {
        name: "duration",
        label: t("passes.duration"),
        sortable: false,
      },
      {
        name: "cost",
        label: t("passes.cost"),
        sortable: false,
      },
      {
        name: "isActive",
        label: t("passes.active"),
        sortable: false,
      },
      {
        name: "createdAt",
        label: t("ui.created_on"),
        sortable: false,
      },
      {
        name: "options",
      },
    ],
    [t]
  );
};
