import * as yup from "yup";
import moment from "moment";

export const LoginSchema = yup.object().shape({
  email: yup.string(),
  password: yup.string(),
});

export const SignupSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("errors.first_name_rq")
    .max(20, "errors.most_character20")
    .min(1, "errors.least_character1"),
  lastName: yup
    .string()
    .required("errors.last_name_rq")
    .max(20, "errors.most_character20")
    .min(1, "errors.least_character1"),
  email: yup
    .string()
    .email("errors.valid_email")
    .required("errors.email_rq"),
  confirmEmail: yup
    .string()
    .email("errors.valid_email")
    .required("errors.email_confirm_rq")
    .oneOf([yup.ref("email"), null], "errors.email_match"),
  password: yup
    .string()
    .required("errors.password_rq")
    .min(8, "errors.least_character8"),
  confirmPassword: yup
    .string()
    .required("errors.password_rq")
    .oneOf([yup.ref("password"), null], "errors.password_match"),
  dateOfBirth: yup.date().test("dateOfBirth", "errors.greater18", value => {
    return moment().diff(value, "years") >= 18;
  }),
  phoneNumber: yup.string().required("errors.phone_rq"),
});

export const CompleteRegSchema = yup.object().shape({
  companyName: yup.string().required("errors.company_name_rq"),
  country: yup.string().required("errors.country_rq"),
  city: yup.string().required("errors.city_rq"),
  postcode: yup.string().required("errors.postcode_rq"),
  address: yup.string().required("errors.address_rq"),
});

export const StartResetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("errors.valid_email")
    .required("errors.email_rq"),
});

export const ResetPasswordSchema = yup.object().shape({
  code: yup.string().required("errors.code_rq"),
  newPassword: yup
    .string()
    .required("errors.new_password_rq")
    .min(8, "errors.least_character8"),
  confirmPassword: yup
    .string()
    .required("errors.password_rq")
    .oneOf([yup.ref("newPassword"), null], "errors.password_match"),
});

export const RequiredChangePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("errors.password_rq")
    .min(8, "errors.least_character8"),
  confirmPassword: yup
    .string()
    .required("errors.password_rq")
    .oneOf([yup.ref("password"), null], "errors.password_match"),
});
