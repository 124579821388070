import React, { useContext, useEffect } from "react";
import { Tab } from "@material-ui/core";
import Toolbar from "../../../package/src/Toolbar";
import ShopEditForm from "../components/information/ShopEditForm";
import Scene from "../../configurator/components/Scene";
import Button from "../../../UI/Button";
import TwoColumnsPage from "../../../package/src/TwoColumnsPage";
import SubscriptionTab from "../components/subscription";
import { useApolloClient, useLazyQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getCompanyWithSubscriptions, getShopCompanyId } from "../graphql/queries";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import MapTab from "../components/map/MapTab";
import { useTranslation } from "react-i18next";
import { ShopEditContext } from "../../../package/src/context/ShopEditContext";
import TabsLarge from "../../../UI/Tabs/TabsLarge";
import CustomTabLabel from "../../../UI/Tabs/CustomTabLabel";
import EditorTab from "../components/editor";
import PaymentTab from "../components/payment";
import CircularProgress from "@material-ui/core/CircularProgress";
import useZones from "../../../package/src/hooks/useZones";

const ShopEditPage = ({match, history, viewer}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const godmode = viewer?.type === "godmode";

  const shopId = match.params.shopId;
  // если нет shopId, значит мы в режиме создания

  const {
    companyId,
    setCompanyId,
    setLoading,
    setOpenSubscription,
    setCurrentTab,
    setPaymentStatus,
    setPaymentPending,
    setSelectedPackageId,
    setPackageId,
    setSubscriptionId,
    setShowOnlyPackageId,
    setPaymentError,
    setNeedRefetch,
    currentTab,
    loading,
    needRefetch,
    paymentError,
    subscriptionId,
    selectedPackageId,
    packageId,
    resetState,
  } = useContext(ShopEditContext);

  useZones();

  useEffect(() => {
    return () => {
      resetState();
    }
  }, []);

  const [ getCompanySubscriptions, { data: dataRecord } ] = useLazyQuery(getCompanyWithSubscriptions, {
    fetchPolicy: "network-only",
    variables: {
      companyId,
    },
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
    },
  });

  const getCompanyId = () => {
    if (godmode && shopId) {
      return apolloClient.query({
        query: getShopCompanyId,
        variables: {
          id: shopId,
        }
      });
    }

    setCompanyId(viewer.companyId);
    return Promise.resolve();
  }

  useEffect(() => {
    getCompanyId()
      .then(res => {
        if (res) {
          setCompanyId(res.data.shop.company._id);
        }

        return getCompanySubscriptions();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }, [])

  useEffect(() => {
    setLoading(false);

    if (!dataRecord) {
      return;
    }

    const records = dataRecord.company.subscriptions;
    // console.log('records', records);

    // no package, no subscription

    if (records.length === 0) {
      setCurrentTab('sub');
      setPaymentStatus(null);
      return;
    }

    // pending

    const dataPending = records.find(item => item.status === 'pending');
    //console.log('dataPending', dataPending);

    if (dataPending && !shopId) {
      // we got pending components, but page is creating shop
      setPaymentStatus('pending');
      setPaymentPending(true);
      setSelectedPackageId(dataPending.subscriptionPackageId); // select this plan to show
      setPackageId(dataPending.subscriptionPackageId); // active plan
      setCurrentTab('sub');
      return;
    }

    // open

    // сброс ошибок
    setPaymentError(false);
    setPaymentPending(false);

    const dataOpen = records.find(item => item.status === 'open');
    setOpenSubscription(dataOpen);
    // console.log('dataOpen', dataOpen);

    if (dataOpen && !shopId) {
      // we got open sub, but page is creating shop
      setPaymentStatus('open');
      setSubscriptionId(dataOpen._id); // will be used in 'addSubscription' mutation
      setPackageId(dataOpen.subscriptionPackageId); // active plan
      setCurrentTab('info');
      return;
    }

    if (dataOpen && shopId) {
      // we got open sub, but page is editing shop
      setShowOnlyPackageId(dataOpen.subscriptionPackageId);
    } else {
      setShowOnlyPackageId(null);
    }

    // taken, page is creating shop

    if (!shopId) {
      setPaymentStatus('taken');
      setCurrentTab(shopId ? 'info' : 'sub');
      return;
    }

    // taken, page is editing shop
    // здесь может быть ещё и pending статус!

    const dataTaken = records.find(item =>
      shopId === item.shopId &&
      item.status === 'taken'
    );
    //console.log('dataTaken', dataTaken);

    if (dataTaken) {
      setPackageId(dataTaken.subscriptionPackageId); // active plan
    }

    if (dataPending) {
      // если у юзера есть pending, то нельзя изменить план
      setShowOnlyPackageId('1');
    }

    setPaymentStatus(dataPending ? 'pending' : 'taken');
    setCurrentTab(shopId ? 'info' : 'sub');
  }, [dataRecord])

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const renderTab = () => {
    switch (currentTab) {
      case 'sub': return <SubscriptionTab
        viewer={viewer}
        shopId={shopId}
      />;
      case 'info': {
        if (needRefetch) {
          setNeedRefetch(false);
          setSelectedPackageId(null);
          return null;
        }

        return <ShopEditForm
          shopId={shopId}
          viewer={viewer}
        />;
      }
      case 'map': return <MapTab
        shopId={shopId}
        packageId={packageId}
        isSubscriptionMode={false}
      />;
      case 'conf': return <Scene
        shopId={shopId}
        companyId={companyId}
        godmode={godmode}
      />;
      case 'editor': return <EditorTab
        shopId={shopId}
      />;
      case 'payment': return <PaymentTab
        companyId={companyId}
        shopId={shopId}
      />;
      default: return null;
    }
  }

  const handleCancel = () => {
    history.push('/shops');
  }

  const disableTabs = {
    info: !shopId && !subscriptionId || paymentError, // скрывать в режиме создания, если нет актуальной подписки, либо есть paymentError
    map: !shopId,
    conf: !shopId,
    payment: !selectedPackageId, // скрывать, если не выбран пакет
    editor: !shopId,
  }

  return (
    <>
      <Toolbar title={shopId ? t('shops.edit_shop') : t('shops.create_shop')} />
      {
        loading || !currentTab ?
          <PageStatusMessage>
            <CircularProgress />
          </PageStatusMessage> :
          <>
            <TwoColumnsPage>
              <TabsLarge
                value={currentTab}
                onChange={handleChange}
              >
                <Tab
                  label={<CustomTabLabel>
                    {t('shops.subscription')}
                  </CustomTabLabel>}
                  value='sub'
                />
                <Tab
                  disabled={disableTabs.info}
                  label={<CustomTabLabel
                    disabled={disableTabs.info}
                  >
                    {t('shops.information')}
                  </CustomTabLabel>}
                  value='info'
                />
                <Tab
                  disabled={disableTabs.map}
                  label={<CustomTabLabel
                    disabled={disableTabs.map}
                  >
                    {t('shops.map')}
                  </CustomTabLabel>}
                  value='map'
                />
                <Tab
                  disabled={disableTabs.conf}
                  label={<CustomTabLabel
                    disabled={disableTabs.conf}
                  >
                    {t('shops.configurator')}
                  </CustomTabLabel>}
                  value='conf'
                />
                <Tab
                  disabled={disableTabs.payment}
                  label={<CustomTabLabel
                    disabled={disableTabs.payment}
                  >
                    Payment
                  </CustomTabLabel>}
                  value='payment'
                />
                <Tab
                  disabled={disableTabs.editor}
                  label={<CustomTabLabel
                    disabled={disableTabs.editor}
                  >
                    {t('shops.3d_editor')}
                  </CustomTabLabel>}
                  value='editor'
                />
              </TabsLarge>
              <Button
                mytype='secondary'
                width='180px'
                handleClick={handleCancel}
              >
                {t('ui.cancel')}
              </Button>
            </TwoColumnsPage>

            {renderTab()}
          </>
      }
    </>
  );
}

export default ShopEditPage;
