import { IconNavMerchants } from "../../icons";
import PassesPage from "./pages/PassesPage";
import FormPassInformation from "./pages/FormPassInformation";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  registerRoute({
    group: "navigation",
    priority: 83,
    path: "/passes",
    MainComponent: PassesPage,
    IconComponent: IconNavMerchants,
    navigationItemLabel: "menu.passes",
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/passes/create",
    MainComponent: FormPassInformation,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/passes/edit/:passId",
    MainComponent: FormPassInformation,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });
}
