import React, { useCallback, useEffect, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getAllSpaces } from "../graphql/queries";
import DataTable from "../../../UI/DataTable/DataTable";
import MenuItem from "../../../UI/MenuItem/MenuItem";
import FormControlLabel from "../../../UI/FormControlLabel/FormControlLabel";
import Dropdown from "../../../UI/Dropdown";
import { useSpacesColumns } from "../hooks/useSpacesColumns";
import { useTranslation } from "react-i18next";
import useModifiers from "../hooks/useModifiers";
import Search from "../../../UI/Search";

const defaultFilters = {
  zoneId: "",
  type: "",
};

const zoneId = {
  turkey: "36683193-e43b-472d-a1ea-498814a6daf7",
  saudiArabia: "cd710bd1-e72f-4ccc-bd2e-ffdb502379ad",
  abodybad: "0fadb8cc-22a3-4cc2-a4c0-b4dfb4fe0c7d",
};

const useStyles = makeStyles(theme => ({
  columnLeft: {
    display: "flex",
    gap: 20,
  },
  flexEnd: {
    display: "flex",
    gap: 20,
    justifyContent: "flex-end",
  },
  flexStart: {
    display: "flex",
    gap: 20,
  },
}));

const SpacesPage = () => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { spaceModifyData } = useModifiers();

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [filters, setFilters] = useState(defaultFilters);
  const [reloadTable, setReloadTable] = useState(false);
  const { columns } = useSpacesColumns();

  const onFetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      const isSearchActive = searchValue.trim() !== "";

      const queryFilters = {
        zoneId: filters.zoneId || undefined,
        type: filters.type || undefined,
      };

      apolloClient
        .query({
          query: getAllSpaces,
          variables: {
            input: {
              filters: queryFilters,
              paging: {
                page: pageIndex + 1,
                limit: isSearchActive ? 99999 : pageSize,
              },
            },
          },
          fetchPolicy: "network-only",
        })
        .then(res => {
          const filteredData = res.data.spaces.data.filter(space =>
            space.name.toLowerCase().includes(searchValue.toLowerCase())
          );
          const totalCount = res.data.spaces.paging.total;
          setTotalCount(totalCount);
          setPageCount(Math.ceil(totalCount / pageSize));
          setTableData(filteredData);
        })
        .catch(e => {
          enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
          console.log(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [t, filters, searchValue]
  );

  const dataProps = useMemo(() => {
    return spaceModifyData(tableData);
  }, [tableData, spaceModifyData, filters]);

  const handleFilterRadio = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const filtersList = useMemo(
    () => [
      {
        isComplex: true,
        content: t("menu.zones"),
        MenuItemsList: (
          <RadioGroup
            onChange={e => handleFilterRadio("zoneId", e.target.value)}
            value={filters.zoneId}
          >
            <MenuItem>
              <FormControlLabel
                value={zoneId.turkey}
                control={<Radio />}
                label={t("spaces.turkey")}
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                value={zoneId.saudiArabia}
                control={<Radio />}
                label={t("spaces.saudi_arabia")}
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                value={zoneId.abodybad}
                control={<Radio />}
                label={t("spaces.abodybad")}
              />
            </MenuItem>
          </RadioGroup>
        ),
      },
      {
        isComplex: true,
        content: t("spaces.types"),
        MenuItemsList: (
          <RadioGroup
            onChange={e => handleFilterRadio("type", e.target.value)}
            value={filters.type}
          >
            <MenuItem>
              <FormControlLabel value="custom" control={<Radio />} label={t("spaces.custom")} />
            </MenuItem>
            <MenuItem>
              <FormControlLabel value="street" control={<Radio />} label={t("spaces.street")} />
            </MenuItem>
            <MenuItem>
              <FormControlLabel value="editor" control={<Radio />} label={t("spaces.editor")} />
            </MenuItem>
          </RadioGroup>
        ),
      },
      {
        isFooter: true,
        content: t("ui.clear_filters"),
        action: () => setFilters(defaultFilters),
      },
    ],
    [filters, t]
  );

  const handleSearch = value => {
    setSearchValue(value.trim());
  };

  const refetch = () => {
    setReloadTable(prev => !prev);
  };

  return (
    <>
      <Toolbar title={t("menu.spaces")} />
      <Grid container spacing={2}>
        <Grid item className={classes.columnLeft}>
          <Dropdown title={t("spaces.filter_spases")} itemsList={filtersList} />
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t("ui.search")}
            useDebounce
          />
        </Grid>
        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          reloadTable={reloadTable}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          filtersByKey={filters}
          defaultSortField="createdAt"
          defaultSortOrder="desc"
          showCheckboxes={false}
          maxHeight="calc(100vh - 280px)"
          maxHeightLaptop="calc(100vh - 280px)"
          maxHeightMobile="calc(100vh - 280px)"
          marginTopMobile="16px"
        />
      </Grid>
    </>
  );
};

export default SpacesPage;
