import { Container, styled } from "@material-ui/core";
import { Form } from "../../../UI/Form/MyForm";
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import { Controller, useForm } from "react-hook-form";
import Checkbox from "../../../UI/Checkbox";
import InputLabel from "../../../UI/Form/InputLabel";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import FormNote from "../../../UI/Form/FormNote";
import { useCallback, useEffect, useRef, useState } from "react";
import { useApolloClient } from "react-apollo";
import { getAllPasses } from "../graphql/restricted-space/queries";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import {
  removeSpaceRestrictions,
  restrictSpace,
  updateSpace,
} from "../graphql/restricted-space/mutations";

const StyledContainer = styled(Container)`
  width: 100%;
`;

const SpaceRestricted = ({ data }) => {
  const apolloClient = useApolloClient();
  const [passesItems, setPassesItems] = useState([]);
  const [pases, setPasses] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { spacesId } = useParams();
  const previousPasses = useRef([]);
  const isBatch = useRef(false);
  const [isRestricted, setIsRestricted] = useState(false);

  const restrictedToSpace = data?.restrictedTo;

  useEffect(() => {
    setIsRestricted(data?.isRestricted || false);
  }, [data]);

  useEffect(() => {
    apolloClient
      .query({
        query: getAllPasses,
        variables: {
          input: {
            filters: {
              isActive: true,
            },
          },
        },
        fetchPolicy: "network-only",
      })
      .then(res => {
        const passes = res.data.passes.data;

        const updatePasses = passes.map(s => ({
          value: s.id,
          label: s.name,
          selected: passes.includes(s.id),
        }));

        setPassesItems(updatePasses);
        if (restrictedToSpace) {
          const filteredRestricted = restrictedToSpace.filter(el => el !== null).map(el => el?.id);
          previousPasses.current = filteredRestricted;
          setPasses(filteredRestricted);
        }
      })
      .catch(e => {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      });
  }, [setPassesItems, restrictedToSpace]);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const handleIsRestrictedChange = useCallback(
    async event => {
      const newValue = event.target.checked;
      setIsRestricted(newValue);

      try {
        await apolloClient.mutate({
          mutation: updateSpace,
          variables: {
            input: {
              id: spacesId,
              isRestricted: newValue,
            },
          },
        });
        enqueueSnackbar(t("snackbar.update_success"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      }
    },
    [spacesId, t]
  );

  const handleChangePass = useCallback(
    async arr => {
      const newPasses = arr.map(k => k.value);
      const addedPasses = newPasses.filter(id => !previousPasses.current.includes(id));
      const removedPasses = previousPasses.current.filter(id => !newPasses.includes(id));

      try {
        if (addedPasses.length > 1) {
          isBatch.current = true;
        }

        for (const passId of addedPasses) {
          await apolloClient.mutate({
            mutation: restrictSpace,
            variables: {
              input: {
                spaceId: spacesId,
                passId: passId,
              },
            },
          });
          if (!isBatch.current) {
            enqueueSnackbar(t("spaces.restrict_success"), { variant: "success" });
          }
        }

        if (isBatch.current) {
          enqueueSnackbar(t("spaces.restrict_success_multiple"), { variant: "success" });
          isBatch.current = false;
        }

        for (const passId of removedPasses) {
          await apolloClient.mutate({
            mutation: removeSpaceRestrictions,
            variables: {
              input: {
                spaceId: spacesId,
                passId: passId,
              },
            },
          });
          enqueueSnackbar(t("spaces.remove_success"), { variant: "success" });
        }

        previousPasses.current = newPasses;
        setPasses(newPasses);
      } catch (e) {
        enqueueSnackbar(t("snackbar.common_error"), { variant: "error" });
        console.log(e.message);
      }
    },
    [spacesId, t]
  );

  return (
    <FormCard>
      <Form width="100%">
        <FormHeader>{t("spaces.passes")}</FormHeader>

        <CheckboxContainer>
          <Controller
            name="isRestricted"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={isRestricted}
                onChange={e => {
                  handleIsRestrictedChange(e);
                }}
              />
            )}
          />
          {t("spaces.isRestricted")}
        </CheckboxContainer>

        <InputLabel>{t("spaces.passes")}</InputLabel>
        <SelectAllAutocomplete
          items={passesItems}
          placeholder={t("spaces.choose_passes")}
          selectAllLabel={t("spaces.all_passes")}
          value={pases}
          onChange={handleChangePass}
        />
        <FormNote>{t("spaces.choose_passes_hint")}</FormNote>
      </Form>
    </FormCard>
  );
};
export default SpaceRestricted;
