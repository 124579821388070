export const constants = {
  APP_NAME: "Wondersouq",
  MERCHANT_REQUESTS: {
    TYPE: {
      E_COMMERCE_ENABLED: "eCommerceEnabled",
      SHOP_CATEGORY: "shopCategory",
    },
    STATE: {
      APPROVED: "approved",
      DECLINED: "declined",
      PENDING: "pending",
    },
  },
  ACCOUNT_TYPES: {
    godmodeAdmin: "godmode-admin",
    godmodeAssistantAdmin: "godmode-assistant-admin",
    godmodeFinancialManager: "godmode-financial-manager",
    godmodeAccountManager: "godmode-account-manager",
    merchantAdmin: "merchant-admin",
    merchantAssistantAdmin: "merchant-assistant-admin",
    merchantFinancialManager: "merchant-financial-manager",
    merchantShopFinancialManager: "merchant-shop-financial-manager",
    merchantProductManager: "merchant-product-manager",
    merchantShopProductManager: "merchant-shop-product-manager",
    systemManager: "system-manager",
  },
  OPTIONS: {
    editShop: "edit-shop",
    approve: "approve",
    decline: "decline",
    ban: "ban",
    sentToApprove:"sent-to-approve",
    editOverrides: "edit-overrides",
    copyLink: "copy-link",
    editCategory: "edit-category",
    deleteCategory: "delete-category",
    cancelOrder: "cancel-order",
    orderDetails: "order-details",
    refundDetails: "refund-details",
    editProduct: "edit-product",
    makeVisible: "make-visible",
    makeHidden: "make-hidden",
    delete: "delete",
    resetPassword: "reset-password",
    blockShopper: "block-shopper",
    unblockShopper: "unblock-shopper",
    deleteShopper: "delete-shopper",
    enable: "enable",
    suspend: "suspend",
    resendEmail: "resend-email",
    cancelInvitation: "cancel-invitation",
    requestVariables: "request-variables",
    requestQueries: "request-queries",
    editTrigger: "edit-trigger",
    deleteTrigger: "delete-trigger",
    editCoupon: "edit-coupon",
    deleteCoupon: "delete-coupon",
    editEvent: "edit_event",
    deleteEvent: "delete-event",
    editSale: "edit-sale",
    deleteSale: "delete-sale",
    editMerchant: "edit-merchant",
    showAllShops: "show-all-shops",
    deleteAccount: "delete-account",
    editPackage: "edit-package",
    editTag: "edit-tag",
    editBrand: "edit-brand",
    deleteBrand: "delete-brand",
    deletePass: "delete-pass",
    editPass: "edit-pass",


  },
  COOKIE_EXPIRES_IN_DAYS: 30,
};

export const colors = {
  purpleGradient: 'linear-gradient(199.68deg, #2352C6 -10.17%, #E988F1 109.02%)',
  purpleGradientReverse: 'linear-gradient(150.46deg, #E988F1 4.69%, #4270FF 88.39%)',
  blueGradient: 'linear-gradient(180deg, #4270FF 0%, #164EFF 100%)',
  blue: '#4270FF',
  violetGradient: 'linear-gradient(268.39deg, #8438C0 0%, #3945AE 100%)',
  rainbow1: 'radial-gradient(97.76% 227.21% at 4.93% 7.34%, #FC7BC8 0%, #39D0FF 48.44%, #7015B8 100%)',
  rainbow2: 'radial-gradient(97.76% 227.21% at 4.93% 7.34%, rgba(195, 73, 230, 0.5) 0%, rgba(255, 182, 126, 0.5) 48.44%, rgba(112, 21, 184, 0.5) 100%)',
  black: '#222222',
  textGrey: '#BFBFBF',
  inputNormal: '#F3F3F3',
  inputFocus: '#F7F7F7',
  backgroundGrey: '#EFF1F6',
  darkGrey: '#9A9A9A',
  red: '#D44C4C',
  redLight: '#DF5656',
  redLight10: 'rgba(223, 86, 86, .1)',
  redLighter: '#F06969',
  whiteBackground: 'linear-gradient(180deg, #FFFFFF 0%, #F1F5FF 100%)',
  white8: '#F8F8F8',
  white6: '#F6F6F6',
  brize: '#C9CFE2',
  brizeLight: '#E5E9F6',
  grass: '#62CF7A',
  yellow: '#FFD951',
}

export const standardPageStyles = {
  gridContainer: {
    position: 'relative',
  },
  grid: {
    height: '42px',
    position: 'absolute',
    top: -92,
    zIndex: 0,
  },
  gridEmpty: {
    height: '42px',
  },
  secondColumn: {
    paddingLeft: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
  },
  secondColumnRtl: {
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
  },
  secondColumnXS: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginBottom: 28,
  },
  firstRow: {
    width: '100%',
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
  },
  secondRow: {
    width: '100%',
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
  },
  selectSomeShops: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: '16px',
    color: colors.black,
  },
}
