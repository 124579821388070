import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import TagToolbar from "../../../package/src/Toolbar";
import { useTranslation } from "react-i18next";
import FormNote from "../../../UI/Form/FormNote";
import { useFetchData } from "../hooks/useFetchData";
import Button from "../../../UI/Button";
import { useHistory, useLocation } from "react-router";
import SpaceRestricted from "../components/SpaceRestricted";

const useStyles = makeStyles(theme => ({
  link: {
    color: "inherit",
    cursor: "pointer",
    textDecoration: "underline",
    textDecorationColor: theme.palette.grey[500],
    textDecorationThickness: "1px",
  },
}));

const keyMapping = {
  defaultSpace: "spaces",
  space: "spaces",
};

const CatalogPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useFetchData();
  const history = useHistory();
  const location = useLocation();
  const shouldShowSpaceRestricted = location.pathname.includes("spaces");

  const renderData = (obj, isNested = false, parentKey = "") => {
    if (!obj) return null;
    return Object.entries(obj).map(([key, value]) => {
      if (key.startsWith("_") || key.startsWith("queryType") || key.startsWith("restricted")) {
        return null;
      }

      if (typeof value === "object" && value !== null) {
        return (
          <Grid item key={value.id}>
            <FormHeader>{key}</FormHeader>
            {Array.isArray(value)
              ? value.map(item => (
                  <FormNote key={item.id} style={{ color: "black", margin: "12px" }}>
                    {typeof item === "object" ? renderData(item, true, key) : item?.toString()}
                  </FormNote>
                ))
              : renderData(value, true, key)}
          </Grid>
        );
      }

      if (key === "id" && isNested) {
        const type = keyMapping[parentKey] || parentKey;
        return (
          <FormCard style={{ padding: "20px" }} key={value}>
            <InputLabel>{key}</InputLabel>
            <FormNote style={{ color: "black", margin: "12px" }}>
              <a
                className={classes.link}
                href="#"
                onClick={() => history.push(`/${type}/catalog/${value}`)}
              >
                {value}
              </a>
            </FormNote>
          </FormCard>
        );
      }

      return (
        <FormCard style={{ padding: "20px" }} key={value?.toString() || key}>
          <InputLabel>{key}</InputLabel>
          <FormNote style={{ color: "black", margin: "12px" }}>{value?.toString()}</FormNote>
        </FormCard>
      );
    });
  };

  const cancelHandler = () => {
    history.goBack();
  };

  return (
    <>
      <TagToolbar title={data?.queryType && data?.name ? `${data.queryType}: ${data.name}` : " "} />
      <Grid container justify="flex-end" spacing={2} style={{ padding: "20px" }}>
        <Button
          mytype="secondary"
          style={{ width: "180px", marginBottom: "20px" }}
          onClick={cancelHandler}
        >
          {t("ui.cancel")}
        </Button>
      </Grid>
      <Grid container spacing={2}>
        {shouldShowSpaceRestricted && <SpaceRestricted data={data} />}
        {renderData(data)}
      </Grid>
    </>
  );
};

export default CatalogPage;
